export const TRANSFER_BASE_PATH = "/transfer";
export const SAVING_BASE_PATH = "/saving";

export enum Path {
  Root = "/",
  OAuthRedirect = "/oauth-redirect",
  UbbleRedirect = "/ubble-redirect",
  Register = "/new-account",
  Onboarding = "/onboarding",
  OnboardingFile = "/onboarding-file",
  OnboardingFileSubmitted = "/onboarding-finalized",
  AccountBlocked = "/account-blocked",
  AccountBlockedAfterPincodeError = "/pincode-error",
  AccountTransactions = "/account-transactions",
  Recipients = "/transfer/recipients",
  AllAccounts = "/all-accounts",
  Transfer = "/transfer/all",
  SimpleTransfer = "/transfer/simple-transfer",
  Cards = "/cards",
  AllCards = "/all-cards",
  CardOutstandings = "/card-outstandings",
  BillIssuers = "/bill-issuers",
  References = "/references",
  BankContact = "/contact",
  Settings = "/my-profile",
  CashTransfers = "/transfer/cash-transfers",
  UpcomingTransfers = "/transfer/upcoming-transfers",
  Recharge = "/transfer/recharge",
  TransactionCodes = "/transfer/transaction-codes",
  ExternalAccount = "/transfer/external-account",
  Loans = "/loans",
  PspRedirect = "/psp-redirect",
  Connect = "/connect",
  TransferMenu = "/transfer",
  UbbleCheck = "/ubble-check",
  JustifyTransaction = "/justify-transaction",
  ErrorScreen = "/error-screen",
  CreateSavingAccount = "/saving/create-saving-account",
}
