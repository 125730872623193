import { useCallback, useMemo } from "react";

import { billIssuerManager, billIssuerSelectionManager, configurationManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import type { BillCategory, BillIssuer, BillIssuerType } from "./bill";

export const useBillIssuer = (issuerTypes?: BillIssuerType[]) => {
  const billIssuers = useObservable(billIssuerManager.issuers);
  const loading = useObservable(billIssuerManager.loading);
  const refreshing = useObservable(billIssuerManager.refreshing);
  const error = useObservable(billIssuerManager.error);
  const cacheStatus = useObservable(billIssuerManager.cacheStatus);
  const search = useObservable(billIssuerSelectionManager.search);
  const hierarchy = useObservable(billIssuerSelectionManager.hierarchy);

  const setSearch = useCallback(
    (searchParam: string | undefined) => billIssuerSelectionManager.search.set(searchParam),
    [],
  );

  const filteredCategories = useMemo(
    () =>
      issuerTypes
        ? billIssuers.filter((billIssuer) => issuerTypes.includes(billIssuer.id as BillIssuerType))
        : billIssuers,
    [issuerTypes, billIssuers],
  );

  const allChildrenIssuers = useMemo(
    () =>
      filteredCategories.reduce<BillIssuer[]>(
        (acc, curr) =>
          [
            ...acc,
            ...(curr.children?.filter((billIssuer) => billIssuer.type === "BILL_ISSUER") as BillIssuer[]),
          ].filter((billIssuer) => (search ? billIssuer.name.toLowerCase().includes(search.toLowerCase()) : true)),
        [],
      ) || [],
    [filteredCategories, search],
  );

  const setHierarchy = useCallback(
    (hierarchyParam: BillCategory[]) => billIssuerSelectionManager.hierarchy.set(hierarchyParam),
    [],
  );

  const load = useCallback(() => {
    billIssuerManager.load();
  }, []);

  const refresh = useCallback(() => {
    billIssuerManager.refresh();
  }, []);

  const refreshSearch = useCallback(() => {
    setSearch("");
  }, [setSearch]);

  const billIssuerColors = configurationManager.getBillIssuerColors();

  return {
    billIssuers,
    allChildrenIssuers,
    filteredCategories,
    billIssuerColors,
    refreshing,
    load,
    loading,
    error,
    cacheStatus,
    search,
    hierarchy,
    refresh,
    refreshSearch,
    setSearch,
    setHierarchy,
  };
};
