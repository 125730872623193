import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import {
  authenticationManager,
  authExecutor,
  configurationManager,
  featuresManager,
  i18NManager,
} from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import { Path } from "../../../core/routing/path";
import { changeLanguage } from "../../../domain/language/language";
import { useRTL } from "../../../domain/language/use-rtl";
import { getLanguageName } from "../../../utils/translations";
import { PrimaryButton, WhitePrimaryButton } from "../../common/buttons/primary-button";
import { BankContact } from "../../common/contact/bank-contact-view";
import { ExternalSites } from "../../common/external-sites-view";
import { ResponsiveContainer } from "../../common/responsive-container";
import { StoreButtons } from "../../common/store-buttons-view";
import { StaticAssets } from "../../styles/custom-assets";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { SettingsSelectItem } from "../settings/components/settings-item";
import { ImagesCarousel } from "./components/image-carousel";
import { VersionBox } from "./components/version-box";

const WarningIcon = require("../../../assets/images/svg/warning.svg");

export default function LoginScreen() {
  const { formatMessage } = useIntl();
  const { isRTL } = useRTL();
  const [pageOpacity, setPageOpacity] = useState(0);
  const features = useObservable(featuresManager.features);
  const location = useLocation();
  const hasBeenDisconnectedDueToInactivity = useObservable(authenticationManager.hasBeenDisconnectedDueToInactivity);
  const userLanguage = useObservable(i18NManager.localeTag);
  const configurationLanguages = useObservable(configurationManager.configuration).langs;
  const enrollmentEnabled = useObservable(configurationManager.configuration).enrollment;

  const history = useHistory();

  const goToRegisterPage = () => {
    const path = features.sdaCustomerOnboardingCreate ? Path.Onboarding : Path.Register;
    history.push(path);
  };

  const connect = () => {
    history.push(Path.Root);
    authExecutor.connect();
  };

  useEffect(() => {
    if (authExecutor.withPhoneNumberAndPinCode()) {
      history.push(`${Path.Connect}${location.search ? location.search : ""}`);
    } else {
      setPageOpacity(1);
    }
  }, []);

  return (
    <Page $isRTL={isRTL} opacity={pageOpacity}>
      {hasBeenDisconnectedDueToInactivity && (
        <StyledAlert>
          <WarningIconImage src={WarningIcon} alt="" />
          {formatMessage("authScreen.disconnectedDueToInactivity")}
        </StyledAlert>
      )}
      <PageContent>
        <LeftPanel $isRTL={isRTL}>
          <TopLeftWrapper>
            <WelcomeDataWrapper>
              <Logo src={StaticAssets.logo} />
              <WelcomeTitle>{formatMessage("authScreen.welcome")}</WelcomeTitle>
            </WelcomeDataWrapper>
            <ExternalSites centerWhenSmall={true} />
          </TopLeftWrapper>
          <Spacer />
          <ButtonWrapper>
            <ConnectButtonsWrapper>
              <LoginButton onClick={connect} size="S" $isRTL={isRTL}>
                {formatMessage("authScreen.login")}
              </LoginButton>
              {enrollmentEnabled && (
                <CreateAccountButton
                  onClick={() => {
                    goToRegisterPage();
                  }}
                  size="S"
                >
                  {formatMessage("authScreen.createAccount")}
                </CreateAccountButton>
              )}
            </ConnectButtonsWrapper>
            <StoreButtons />
          </ButtonWrapper>
          <StyledImagesCarousel imagesSource={StaticAssets.carouselImages} />
          <SettingsSelectItem
            style={{ width: "200px" }}
            selectInputProps={{
              value: userLanguage,
              innerId: "language",
              options: configurationLanguages,
              style: { marginTop: "10px" },
              itemRenderer: getLanguageName,
              onChange: (language) => changeLanguage(language),
            }}
          />
        </LeftPanel>
        <RightPanel>
          <ContactSection>
            <BankContact withBigButtons />
          </ContactSection>
          <VersionContainer>
            <VersionBox />
          </VersionContainer>
        </RightPanel>
      </PageContent>
    </Page>
  );
}

const Page = styled(ResponsiveContainer)<{ opacity: number }>``;

const PageContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1 0 auto;
  overflow: hidden;
  padding-bottom: 60px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
  }
`;

const LeftPanel = styled.section<{ $isRTL: boolean }>`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  ${(props) => (props.$isRTL ? "margin-left: 70px" : "margin-right: 70px")};

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-right: unset;
    margin-left: unset;
    align-items: center;
  }
`;

const WelcomeTitle = styled.h1`
  font-size: 1.875rem;
  ${theme.boldText};
  margin-bottom: 30px;
  max-width: 300px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    text-align: center;
  }
`;

const Logo = styled.img`
  width: 84px;
  height: 84px;
  object-fit: contain;
  border-radius: 13px;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

const ConnectButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;
  }
`;

const LoginButton = styled(PrimaryButton)<{ $isRTL: boolean }>`
  ${(props) => (props.$isRTL ? "margin-left: 30px" : "margin-right: 30px")};
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-right: unset;
    margin-left: unset;

    margin-bottom: 20px;
  }
`;

const CreateAccountButton = styled(WhitePrimaryButton)``;

const RightPanel = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 304px;
  padding-bottom: 100px;
  position: relative;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    max-width: unset;
    margin-top: 20px;
  }
`;

const ContactSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 21px;
  ${shadows.medium};
  background-color: #fff;
  padding: 40px 0;
`;

const TopLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const WelcomeDataWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    align-items: center;
  }
`;

const StyledImagesCarousel = styled(ImagesCarousel)`
  margin-bottom: -40px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-bottom: unset;
  }
`;

const StyledAlert = styled.div`
  ${theme.bodyGreyRegular};
  background-color: ${theme.colors.warning[300]};
  color: ${theme.colors.warning[500]};
  font-size: 0.875rem;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const WarningIconImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const VersionContainer = styled.div`
  position: absolute;
  bottom: 70px;
`;
