import { useMemo } from "react";
import type { IntlShape } from "react-intl";

import { useObservable } from "../../utils/observable";
import type { Amount } from "../amount/amount";
import { i18NManager } from "../service/services";

export function useIntl() {
  const intl = useObservable(i18NManager.intl);
  return useMemo(
    () => ({
      ...intl,
      formatAmount: i18NManager.formatAmount,
      formatMessage: i18NManager.formatMessage,
      formatRelativeDate: i18NManager.formatRelativeDate,
    }),
    [intl],
  );
}

export type Intl = Omit<IntlShape, "formatMessage"> & {
  formatAmount: (amount: Amount, absolute?: boolean) => string;
  formatMessage: (id: string, values?: Record<string, string | number | boolean | null | undefined | Date>) => string;
  formatRelativeDate: (date: string) => string;
};
