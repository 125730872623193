import { useCallback, useEffect, useMemo, useState } from "react";

import { useAsyncFunction } from "./utils";

const DEFAULT_PAGE_SIZE = 20;

export interface Paginated<T> {
  items: T[];
  count: number;
  total: number;
  offset: number;
  limit: number;
  sort: string;
}

export interface PaginationOptions {
  offset: number;
  limit?: number;
  sort?: string;
}

export interface PaginatedFetchResult<T> {
  loading: boolean;
  error: Error | null;
  data: T[];
  total: number;
  redo: () => void;
  redoLoading: boolean;
  fetchMore: () => void;
  hasMore: boolean;
  loadingMore: boolean;
  moreError: Error | null;
  redoLoadMore: () => void;
}

export function createEmptyPaginatedData<T>(): Paginated<T> {
  return {
    items: [],
    count: 0,
    total: 0,
    offset: 0,
    limit: 0,
    sort: "",
  };
}

export function usePaginatedFetch<T>(
  fun: (options: PaginationOptions) => Promise<Paginated<T>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps: readonly any[] = [],
  sort?: string,
  limit?: number,
): PaginatedFetchResult<T> {
  const [data, setData] = useState<T[]>([]);
  const [offset, setOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const { loading, error, result, redo, redoLoading } = useAsyncFunction<Paginated<T>>(
    async () => await fun({ offset: 0, limit: limit, sort: sort }),
    [...deps],
  );

  const {
    loading: loadingMore,
    error: moreError,
    result: moreResult,
    redo: redoLoadMore,
    redoLoading: redoLoadingMore,
  } = useAsyncFunction<Paginated<T> | null>(async () => {
    if (offset === 0) {
      return null;
    }
    return await fun({ offset: offset, limit: limit, sort: sort });
  }, [offset]);

  useEffect(() => {
    setData([]);
    setOffset(0);
    setNextOffset(0);
    setTotal(0);
    setHasMore(false);
  }, [...deps]);

  useEffect(() => {
    if (result) {
      setData([...result.items]);
      setTotal(result.total);
      setNextOffset(result.limit);
      setHasMore(result.limit < result.total);
    } else if (error) {
      setData([]);
      setOffset(0);
      setNextOffset(0);
      setTotal(0);
      setHasMore(false);
    }
  }, [setData, setTotal, setNextOffset, setHasMore, result, error]);

  useEffect(() => {
    if (moreResult) {
      setData((data) => [...data, ...moreResult.items]);
      setTotal(moreResult.total);
      setNextOffset(moreResult.offset + moreResult.limit);
      setHasMore(moreResult.offset + moreResult.limit < moreResult.total);
    }
  }, [setData, setTotal, setNextOffset, setHasMore, moreResult]);

  const fetchMore = () => {
    if (hasMore && !loadingMore) {
      setOffset(nextOffset);
    }
  };

  return {
    loading,
    error,
    data,
    total,
    redo,
    redoLoading,
    fetchMore,
    hasMore,
    loadingMore: loadingMore || redoLoadingMore,
    moreError,
    redoLoadMore,
  };
}

export function usePagination<T>(data: T[], pageSize: number = DEFAULT_PAGE_SIZE) {
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    setPageCount(1);
  }, [data, pageSize]);

  const getMore = useCallback(() => {
    setPageCount((count) => count + 1);
  }, []);

  const canShowMore = data.length > pageCount * pageSize;

  return useMemo(
    () => ({
      data: data.slice(0, pageCount * pageSize),
      canShowMore,
      getMore,
    }),
    [data, getMore, pageCount, pageSize],
  );
}

export interface PaginationSource<T> {
  data: T[];
  hasMore: boolean;
  getMore: () => void;
  getMoreError?: Error | null;
}

export function useMultiplePagination<T>(...sources: PaginationSource<T>[]) {
  const [currentSourceIndex, setCurrentSourceIndex] = useState(0);

  const firstSource = sources[0];

  useEffect(() => {
    setCurrentSourceIndex(0);
  }, [firstSource]);

  return {
    data: sources.map((source, index) => (index <= currentSourceIndex ? source.data : [])),
    hasMore: currentSourceIndex < sources.length - 1 || sources[currentSourceIndex].hasMore,
    getMore: useCallback(() => {
      const currentSource = sources[currentSourceIndex];
      if (currentSource.getMoreError) {
        return;
      }
      if (currentSource.hasMore) {
        currentSource.getMore();
      } else {
        if (currentSourceIndex < sources.length - 1) {
          setCurrentSourceIndex(currentSourceIndex + 1);
          sources[currentSourceIndex + 1].getMore();
        }
      }
    }, [currentSourceIndex, sources]),
  };
}
