import { Path } from "../../../web/core/routing/path";
import type {
  AccountBlockedAfterThreePincodeAttemptsError,
  DefaultAccountBlockedError,
} from "../pincode/pincode-error";
import { ErrorType, isAccountBlocked } from "../pincode/pincode-error";

export enum AccountStatus {
  Opened = "OPENED",
  Closed = "CLOSED",
  Blocked = "BLOCKED",
  DebitBlocked = "DEBIT_BLOCKED",
  CreditBlocked = "CREDIT_BLOCKED",
}

export enum BalanceType {
  Available = "AvailableBalance",
  Settlement = "SettlementPositionBalance",
}

export enum AccountType {
  Current = "CURRENT",
  Savings = "SAVINGS",
  Dedicated = "DEDICATED",
  MoneyPot = "MONEYPOT",
  Technical = "TECHNICAL",
}

export type AccountIBAN = string | undefined;

export interface Account {
  balances: BalanceAccount[];
  bic: string;
  createdAt: Date;
  currency: string;
  currencyAlpha: string;
  distributorId: number;
  externalData: { [key: string]: string };
  externalId: string;
  externalReferences: { type: string; reference: string }[];
  iban: string;
  id: string;
  lastChangedAt: Date;
  lastStatusChangedAt: Date;
  lastUsedAt: Date;
  name: string;
  ownerName: string;
  productId: string;
  status: AccountStatus;
  type: AccountType;
  userId: string;
}

export interface BalanceAccount {
  value: number;
  currency: string;
  currencyAlpha?: string;
  display: string;
  balanceType: BalanceType;
}

export function isSupportedAccountType(account: Account): boolean {
  return (
    account.type === AccountType.Current ||
    account.type === AccountType.Dedicated ||
    account.type === AccountType.MoneyPot ||
    account.type === AccountType.Savings ||
    account.type === AccountType.Technical
  );
}

export function getAvailableBalance(account: Account): BalanceAccount {
  return account.balances?.filter((b) => b.balanceType === BalanceType.Available)[0] || null;
}

export function getSettlementBalance(account: Account): BalanceAccount {
  return account.balances?.filter((b) => b.balanceType === BalanceType.Settlement)[0] || null;
}

export function redirectionHandledOnAccountBlocked(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  redirect: (path: string) => void,
): error is AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError {
  if (isAccountBlocked(error)) {
    redirect(
      error.type === ErrorType.DefaultAccountBlocked ? Path.AccountBlocked : Path.AccountBlockedAfterPincodeError,
    );
    return true;
  }
  return false;
}
