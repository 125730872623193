import { CreditScope, SavingsScope, ServiceDomainScope, SharedServiceDomainScope } from "../authentication/scope";

export const CREDIT_SCOPES: CreditScope[] = [
  CreditScope.CdtProductRead,
  CreditScope.CdtCreditRequestRead,
  CreditScope.CdtCreditRequestWrite,
  CreditScope.CdtCreditServicingRead,
];

export const SAVINGS_SCOPES: SavingsScope[] = [
  SavingsScope.SavContractRead,
  SavingsScope.SavDeposit,
  SavingsScope.SavProductRead,
  SavingsScope.SavContractWrite,
];

export const SERVICE_DOMAIN_SCOPES: (ServiceDomainScope | SharedServiceDomainScope)[] = [
  ServiceDomainScope.DocumentSetupView,
  ServiceDomainScope.AdditionalDataRead,
  ServiceDomainScope.FormRead,
  SharedServiceDomainScope.PaymentNetwork,
  SharedServiceDomainScope.PaymentContract,
  SharedServiceDomainScope.CustomerInstructionView,
  SharedServiceDomainScope.CustomerInstructionInitiation,
  SharedServiceDomainScope.CustomerInstructionUpdate,
  SharedServiceDomainScope.CustomerInstructionDeletion,
  SharedServiceDomainScope.CustomerInstructionSubmit,
  SharedServiceDomainScope.SdaAuthenticationsSessionsAuth,
  SharedServiceDomainScope.SdaAuthenticationsSessionsView,
  SharedServiceDomainScope.SdaAccountView,
  SharedServiceDomainScope.RecipientCreate,
  SharedServiceDomainScope.PaymentInstructionView,
  SharedServiceDomainScope.SdaAccountsNameUpdate,
  SharedServiceDomainScope.SdaRequiredActionsWrite,
];
