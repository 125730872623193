import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { useOnboardingFile } from "../../../../../../shared/domains/onboarding/use-onboarding";
import { theme } from "../../../../styles/theme";

const WarningIcon = require("../../../../../assets/images/svg/warning.svg");

export const OnboardingFileDelegateErrorStatusCard: React.FC = () => {
  const { clientDelegateErrorDetected } = useOnboardingFile();
  const { formatMessage } = useIntl();

  return clientDelegateErrorDetected ? (
    <Container>
      <TitleContainer>
        <Title>{formatMessage("onboardingDelegateErrorStatusCard.title")}</Title>
        <WarningIconImage src={WarningIcon} alt="" />
      </TitleContainer>
    </Container>
  ) : null;
};

const Container = styled.div`
  flex: 1;
  background-color: #ffecd1;
  border-radius: 13px;
  padding: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Title = styled.div`
	${theme.bodyBlackBold}
	color: '#000000'};
	flex-grow: 1;
	flex-shrink: 1;
    padding-right: 8px;
`;

const WarningIconImage = styled.img`
  width: 24px;
  height: 24px;
`;
