import { useMemo } from "react";

import type { Account } from "../account/account";
import type { UrlLink } from "../BaseUrl";
import type { Address } from "./address";
import type { Gender } from "./gender";

export enum ClientStatus {
  Initiated = "INITIATED",
  Pending = "PENDING",
  Opened = "OPENED",
  Blocked = "BLOCKED",
  Closed = "CLOSED",
  Iniblocked = "INIBLOCKED",
}

export interface Client {
  mobile: string;
  alias: string;
  lastName?: string;
  firstName?: string;
  maidenName?: string;
  postName?: string;
  email?: string;
  lang?: string;
  address?: Address;
  birthDate?: string;
  birthPlace?: string;
  gender?: Gender;
  contactphone?: string;
  lastLogin?: string;
  mustChangePincode: boolean;
  status: ClientStatus;
  links?: UrlLink[];
  distributorConfigurationCode?: string;
}

export function useClientDisplayName(client: Client | Account | null | undefined, withoutMaidenName?: boolean) {
  return useMemo(() => {
    let result = "";
    if (client && (client as Account).label) {
      return (client as Account).label;
    } else if (client) {
      const maidenName =
        !withoutMaidenName && (client as Client).maidenName ? `(${(client as Client).maidenName})` : "";
      result = [(client as Client).firstName, (client as Client).lastName, (client as Client).postName, maidenName]
        .filter(Boolean)
        .join(" ");
    }
    return result;
  }, [client, withoutMaidenName]);
}

export function clientMailName(client: Client | null | undefined) {
  let result = "";
  if (client) {
    result = [client.firstName?.trim(), client.lastName?.trim(), client.postName?.trim()].filter(Boolean).join(" ");
    if (client.maidenName) {
      result += ` (${client.maidenName.trim()})`;
    }
  }

  return result;
}
