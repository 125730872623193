import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { accountManager } from "../../../../shared/core/service/services";
import { useAccounts } from "../../../../shared/domains/account/use-accounts";
import { useObservable } from "../../../../shared/utils/observable";
import { repeat } from "../../../../shared/utils/utils";
import { Path } from "../../../core/routing/path";
import { useClient } from "../../../domain/authentication/use-client";
import { useRTL } from "../../../domain/language/use-rtl";
import { AccountListGrid } from "../../common/account/account-list-grid";
import { TertiaryLink } from "../../common/buttons/tertiary-button";
import { PageHeader } from "../../common/page/page-header";
import { theme } from "../../styles/theme";
import { AccountTile, SkeletonAccountTile } from "./components/account-tile";
import { ACCOUNT_QUERY_PARAM, useSelectedAccount } from "./utils/use-selected-account";

const SKELETON_ACCOUNT_NUMBER = 4;

export default function AllAccountsScreen() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { client } = useClient();
  const accounts = useAccounts();
  const accountsLoading = useObservable<boolean>(accountManager.loading);
  const [focusedAccount] = useSelectedAccount(accounts);

  const { isRTL } = useRTL();

  return (
    <AccountsSection>
      <PageHeader title={formatMessage("accountsList.title")}>
        <GoBackToClassicViewLink
          to={
            focusedAccount
              ? `${Path.AccountTransactions}?${ACCOUNT_QUERY_PARAM}=${focusedAccount.id}`
              : Path.AccountTransactions
          }
        >
          {formatMessage("allAccountsScreen.goBackClassicViewButton")}
        </GoBackToClassicViewLink>
      </PageHeader>
      {!accountsLoading && accounts.length === 0 ? (
        <NoAccountAvailable>{formatMessage("allAccountsScreen.noAccountAvailable")}</NoAccountAvailable>
      ) : (
        <AccountListGrid>
          {accountsLoading
            ? repeat(SKELETON_ACCOUNT_NUMBER, (index) => <SkeletonAccountTile key={index} $isRTL={isRTL} />)
            : accounts.map((account, index) => (
                <StyledAccountTile
                  onAccountClick={(account) =>
                    history.push(`${Path.AccountTransactions}?${ACCOUNT_QUERY_PARAM}=${account.id}`)
                  }
                  key={account.id + index}
                  isActive={account.id === focusedAccount?.id ?? index === 0}
                  account={account}
                  owner={client}
                />
              ))}
        </AccountListGrid>
      )}
    </AccountsSection>
  );
}

const AccountsSection = styled.section`
  align-self: stretch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledAccountTile = styled(AccountTile)`
  text-align: left;
`;

const GoBackToClassicViewLink = styled(TertiaryLink)`
  text-align: center;
`;

const NoAccountAvailable = styled.span`
  ${theme.text};
  margin-top: 20px;
`;
