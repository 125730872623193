import { COUNTRY_CURRENCIES } from "../core/currency/currency";

export const anonimyzeIban = (iban: string) => {
  const splittedIban = iban.split(" ");
  const length = splittedIban.length;
  const anonymizedIban = splittedIban.map((bloc, i) => {
    if (i === 0 || i === length - 1) {
      return bloc;
    } else {
      return bloc.replace(/[0-9A-Z]/g, "•");
    }
  });

  return anonymizedIban.join(" ");
};

export const getCurrencyFromIban = (iban: string) => {
  const ibanCountryCode = iban.slice(0, 2);
  const currency = COUNTRY_CURRENCIES[ibanCountryCode];
  return currency || "EUR";
};

export const getCurrencyFromBic = (bic: string) => {
  const bicCountryCode = bic.slice(4, 6);
  const currency = COUNTRY_CURRENCIES[bicCountryCode];
  return currency || "EUR";
};
