import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../shared/core/service/services";
import { isDefined } from "../../../../shared/utils/assert";
import { buildLinkMailTo, buildLinkPhoneTo } from "../../../../shared/utils/link-builder";
import { useObservable } from "../../../../shared/utils/observable";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number";
import { ContactCard } from "../../common/contact/contact-link";
import { PageSectionTitle } from "../../common/page/page-section-title";
import { StaticAssets } from "../../styles/custom-assets";
import { UIConstants } from "../../styles/uiConstants";
import { ImagesCarousel } from "../login/components/image-carousel";

export const ContactScreen = () => {
  const { formatMessage } = useIntl();
  const { contactEmail, contactPhone } = useObservable(configurationManager.configuration);
  const infoUrl = configurationManager.getBestInfoUrlLocale();
  return (
    <>
      <PageSectionTitle>{formatMessage("bankContactScreen.title")}</PageSectionTitle>
      <ContactsContainer>
        {isDefined(contactEmail) && (
          <ContactCard type="email" href={buildLinkMailTo(contactEmail)} value={contactEmail} />
        )}
        {isDefined(contactPhone) && (
          <ContactCard type="phone" href={buildLinkPhoneTo(contactPhone)} value={formatPhoneNumber(contactPhone)} />
        )}
        {isDefined(infoUrl) && <ContactCard type="website" href={infoUrl} value={infoUrl} />}
      </ContactsContainer>
      <ImagesCarousel imagesSource={StaticAssets.carouselImages} />
    </>
  );
};

const ContactsContainer = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  margin-top: 30px;
  margin-bottom: 40px;

  grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px);
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px);
  }
`;
