{
  "PARTY_AUTHENTICATION_SMS_OTP_ALREADY_SENT": "Un code de vérification a déjà été envoyé à ce numéro de téléphone.",
  "TRANSACTION_WITHDRAW_CODE_GENERATION_ERROR": "Le retrait n'est pas autorisé pour ce point de transaction",
  "common": {
    "today": "Aujourd'hui",
    "yesterday": "Hier",
    "goBack": "Retour",
    "languages": {
      "fr_FR": "Fran\u00e7ais (France)",
      "en_US": "English (USA)",
      "es_ES": "Espa\u00f1ol (Espa\u00f1a)",
      "af_NA": "Afrikaans (Namibia)",
      "af_ZA": "Afrikaans (South Africa)",
      "ak_CI": "Akan (Côte d’Ivoire)",
      "ak_GH": "Akan (Ghana)",
      "gn_PY": "Avañe'ẽ",
      "ay_BO": "Aymar aru",
      "az_AZ": "Azərbaycan dili",
      "id_ID": "Bahasa Indonesia",
      "ms_MY": "Bahasa Melayu",
      "ms_BN": "Bahasa Melayu (Brunei)",
      "ms_CC": "Bahasa Melayu (Cocos (Keeling) Islands)",
      "ms_SG": "Bahasa Melayu (Singapore)",
      "bm_BF": "Bamanakan (Burkina Faso)",
      "bm_CI": "Bamanakan (Côte d’Ivoire)",
      "bm_GM": "Bamanakan (Gambia)",
      "bm_ML": "Bamanakan (Mali)",
      "jv_ID": "Basa Jawa",
      "bh_IN": "Bihari (India)",
      "bs_BA": "Bosanski",
      "br_FR": "Brezhoneg (France)",
      "ca_ES": "Català",
      "ca_AD": "Català (Andorra)",
      "ce_RU": "Chechen (Russia)",
      "sn_BW": "ChiShona (Botswana)",
      "sn_MZ": "ChiShona (Mozambique)",
      "sn_ZW": "ChiShona (Zimbabwe)",
      "ht_GA": "Créole haitien (Grandans)",
      "ht_OU": "Créole haitien (Lwès)",
      "ht_NI": "Créole haitien (Nip)",
      "ht_ND": "Créole haitien (Nò)",
      "ht_NO": "Créole haitien (Nòdwès)",
      "ht_NE": "Créole haitien (Nòdès)",
      "ht_CE": "Créole haitien (Sant)",
      "ht_SD": "Créole haitien (Sid)",
      "ht_SE": "Créole haitien (Sidès)",
      "cy_GB": "Cymraeg",
      "cy_AR": "Cymraeg (Argentina)",
      "da_DK": "Dansk",
      "da_FO": "Dansk (Faroe Islands)",
      "da_DE": "Dansk (Germany)",
      "da_GL": "Dansk (Greenland)",
      "fa_AF": "Dari",
      "se_NO": "Davvisámegiella",
      "de_BE": "Deutsch (Belgium)",
      "de_DK": "Deutsch (Denmark)",
      "de_DE": "Deutsch (Deutschland)",
      "de_FR": "Deutsch (France)",
      "de_HU": "Deutsch (Hungary)",
      "de_IT": "Deutsch (Italy)",
      "de_LI": "Deutsch (Liechtenstein)",
      "de_LU": "Deutsch (Luxembourg)",
      "de_PL": "Deutsch (Poland)",
      "de_CH": "Deutsch (Schweiz)",
      "de_AT": "Deutsch (Österreich)",
      "dv_MV": "Divehi (Maldives)",
      "dz_BT": "Dzongkha (Bhutan)",
      "et_EE": "Eesti (Estonia)",
      "en_AS": "English (American Samoa)",
      "en_AI": "English (Anguilla)",
      "en_AG": "English (Antigua & Barbuda)",
      "en_AU": "English (Australia)",
      "en_BS": "English (Bahamas)",
      "en_BB": "English (Barbados)",
      "en_BE": "English (Belgium)",
      "en_BZ": "English (Belize)",
      "en_BM": "English (Bermuda)",
      "en_BW": "English (Botswana)",
      "en_IO": "English (British Indian Ocean Territory)",
      "en_VG": "English (British Virgin Islands)",
      "en_BN": "English (Brunei)",
      "en_CM": "English (Cameroon)",
      "en_CA": "English (Canada)",
      "en_KY": "English (Cayman Islands)",
      "en_CK": "English (Cook Islands)",
      "en_DM": "English (Dominica)",
      "en_ER": "English (Eritrea)",
      "en_ET": "English (Ethiopia)",
      "en_FK": "English (Falkland Islands)",
      "en_FJ": "English (Fiji)",
      "en_GM": "English (Gambia)",
      "en_GH": "English (Ghana)",
      "en_GI": "English (Gibraltar)",
      "en_GD": "English (Grenada)",
      "en_GU": "English (Guam)",
      "en_GY": "English (Guyana)",
      "en_HK": "English (Hong Kong SAR China)",
      "en_IN": "English (India)",
      "en_IE": "English (Ireland)",
      "en_IL": "English (Israel)",
      "en_JM": "English (Jamaica)",
      "en_KE": "English (Kenya)",
      "en_KI": "English (Kiribati)",
      "en_LS": "English (Lesotho)",
      "en_LR": "English (Liberia)",
      "en_MW": "English (Malawi)",
      "en_MT": "English (Malta)",
      "en_MH": "English (Marshall Islands)",
      "en_MU": "English (Mauritius)",
      "en_FM": "English (Micronesia)",
      "en_MS": "English (Montserrat)",
      "en_NA": "English (Namibia)",
      "en_NR": "English (Nauru)",
      "en_NZ": "English (New Zealand)",
      "en_NG": "English (Nigeria)",
      "en_NU": "English (Niue)",
      "en_NF": "English (Norfolk Island)",
      "en_MP": "English (Northern Mariana Islands)",
      "en_PK": "English (Pakistan)",
      "en_PW": "English (Palau)",
      "en_PG": "English (Papua New Guinea)",
      "en_PH": "English (Philippines)",
      "en_PI": "English (Pirate)",
      "en_PN": "English (Pitcairn Islands)",
      "en_PR": "English (Puerto Rico)",
      "en_RW": "English (Rwanda)",
      "en_WS": "English (Samoa)",
      "en_SC": "English (Seychelles)",
      "en_SL": "English (Sierra Leone)",
      "en_SG": "English (Singapore)",
      "en_SB": "English (Solomon Islands)",
      "en_SO": "English (Somalia)",
      "en_ZA": "English (South Africa)",
      "en_SH": "English (St. Helena)",
      "en_KN": "English (St. Kitts & Nevis)",
      "en_LC": "English (St. Lucia)",
      "en_VC": "English (St. Vincent & Grenadines)",
      "en_SZ": "English (Swaziland)",
      "en_TK": "English (Tokelau)",
      "en_TO": "English (Tonga)",
      "en_TT": "English (Trinidad & Tobago)",
      "en_TC": "English (Turks & Caicos Islands)",
      "en_UM": "English (U.S. Outlying Islands)",
      "en_VI": "English (U.S. Virgin Islands)",
      "en_GB": "English (UK)",
      "en_UG": "English (Uganda)",
      "en_UD": "English (Upside Down)",
      "en_VU": "English (Vanuatu)",
      "en_ZM": "English (Zambia)",
      "en_ZW": "English (Zimbabwe)",
      "es_AR": "Español (Argentine)",
      "es_BO": "Español (Bolivia)",
      "es_CL": "Español (Chile)",
      "es_CO": "Español (Colombia)",
      "es_CR": "Español (Costa Rica)",
      "es_CU": "Español (Cuba)",
      "es_DO": "Español (Dominican Republic)",
      "es_EC": "Español (Ecuador)",
      "es_SV": "Español (El Salvador)",
      "es_GQ": "Español (Equatorial Guinea)",
      "es_US": "Español (Estados Unidos)",
      "es_GT": "Español (Guatemala)",
      "es_HN": "Español (Honduras)",
      "es_LA": "Español (Latinoamérica)",
      "es_MX": "Español (México)",
      "es_NI": "Español (Nicaragua)",
      "es_PA": "Español (Panama)",
      "es_PY": "Español (Paraguay)",
      "es_PE": "Español (Peru)",
      "es_PR": "Español (Puerto Rico)",
      "es_UY": "Español (Uruguay)",
      "es_VE": "Español (Venezuela)",
      "eo_EO": "Esperanto",
      "eu_ES": "Euskara",
      "ee_GH": "Eʋegbe (Ghana)",
      "ee_TG": "Eʋegbe (Togo)",
      "tl_PH": "Filipino",
      "fr_AD": "Français (Andorra)",
      "fr_BE": "Français (Belgique)",
      "fr_BJ": "Français (Benin)",
      "fr_BF": "Français (Burkina Faso)",
      "fr_BI": "Français (Burundi)",
      "fr_CM": "Français (Cameroon)",
      "fr_CA": "Français (Canada)",
      "fr_CF": "Français (Central African Republic)",
      "fr_TD": "Français (Chad)",
      "fr_KM": "Français (Comoros)",
      "fr_CG": "Français (Congo - Brazzaville)",
      "fr_CD": "Français (Congo - Kinshasa)",
      "fr_CI": "Français (Côte d’Ivoire)",
      "fr_DJ": "Français (Djibouti)",
      "fr_GF": "Français (French Guiana)",
      "fr_PF": "Français (French Polynesia)",
      "fr_GA": "Français (Gabon)",
      "fr_GP": "Français (Guadeloupe)",
      "fr_GN": "Français (Guinea)",
      "fr_HT": "Français (Haiti)",
      "fr_IT": "Français (Italy)",
      "fr_LB": "Français (Lebanon)",
      "fr_LU": "Français (Luxembourg)",
      "fr_MG": "Français (Madagascar)",
      "fr_ML": "Français (Mali)",
      "fr_MQ": "Français (Martinique)",
      "fr_MR": "Français (Mauritanie)",
      "fr_YT": "Français (Mayotte)",
      "fr_MC": "Français (Monaco)",
      "fr_NC": "Français (New Caledonia)",
      "fr_NE": "Français (Niger)",
      "fr_RW": "Français (Rwanda)",
      "fr_RE": "Français (Réunion)",
      "fr_SC": "Français (Seychelles)",
      "fr_PM": "Français (St. Pierre & Miquelon)",
      "fr_CH": "Français (Suisse)",
      "fr_TG": "Français (Togo)",
      "fr_GB": "Français (United Kingdom)",
      "fr_VU": "Français (Vanuatu)",
      "fr_VA": "Français (Vatican City)",
      "fr_WF": "Français (Wallis & Futuna)",
      "fy_NL": "Frysk",
      "fo_FO": "Føroyskt",
      "ga_IE": "Gaeilge (Gaelic)",
      "ga_GB": "Gaeilge (United Kingdom)",
      "gv_GB": "Gaelg (United Kingdom)",
      "gl_ES": "Galego",
      "ha_BF": "Hausa (Burkina Faso)",
      "ha_NE": "Hausa (Niger)",
      "ha_NG": "Hausa (Nigeria)",
      "ha_SD": "Hausa (Sudan)",
      "ha_TG": "Hausa (Togo)",
      "hr_HR": "Hrvatski",
      "hr_BA": "Hrvatski (Bosnia & Herzegovina)",
      "ig_NG": "Igbo (Nigeria)",
      "rn_BI": "Ikirundi (Uburundi)",
      "iu_CA": "Inuktitut (Canada)",
      "nd_ZW": "IsiNdebele (Zimbabwe)",
      "it_IT": "Italiano",
      "it_HR": "Italiano (Croatia)",
      "it_SM": "Italiano (San Marino)",
      "it_SI": "Italiano (Slovenia)",
      "it_CH": "Italiano (Switzerland)",
      "it_VA": "Italiano (Vatican City)",
      "jv_MY": "Javanese (Malaysia)",
      "jv_SR": "Javanese (Suriname)",
      "kl_GL": "Kalaallisut (Greenland)",
      "ks_IN": "Kashmiri (India)",
      "kw_GB": "Kernewek (United Kingdom)",
      "kg_CG": "KiKongo (Congo Brazzaville)",
      "kg_CD": "KiKongo (Congo Kinshasa RDC)",
      "sw_KE": "Kiswahili",
      "sw_TZ": "Kiswahili (Tanzania)",
      "ku_IR": "Kurdish (Iran)",
      "ku_IQ": "Kurdish (Iraq)",
      "ku_TR": "Kurdî",
      "ky_KG": "Kyrgyz (Kyrgyzstan)",
      "lo_LA": "Lao (Laos)",
      "ht_AR": "Latibonit",
      "la_VA": "Latin",
      "lv_LV": "Latviešu",
      "to_TO": "Lea fakatonga (Tonga)",
      "lt_LT": "Lietuvių",
      "ln_CG": "Lingála (Congo - Brazzaville)",
      "ln_CD": "Lingála (Congo - Kinshasa)",
      "lg_UG": "Luganda (Uganda)",
      "lb_DE": "Luxembourgish (Germany)",
      "lb_LU": "Luxembourgish (Luxembourg)",
      "li_NL": "Lèmbörgs",
      "hu_HU": "Magyar",
      "hu_AT": "Magyar (Austria)",
      "hu_RO": "Magyar (Romania)",
      "hu_SK": "Magyar (Slovakia)",
      "hu_SI": "Magyar (Slovenia)",
      "hu_UA": "Magyar (Ukraine)",
      "mg_MG": "Malagasy",
      "mt_MT": "Malti",
      "ro_MD": "Moldavian",
      "mo_MD": "Moldavian (Moldova)",
      "nl_AW": "Nederlands (Aruba)",
      "nl_BE": "Nederlands (België)",
      "nl_NL": "Nederlands (Nederland)",
      "nl_SR": "Nederlands (Suriname)",
      "nb_NO": "Norsk (bokmål)",
      "nn_NO": "Norsk (nynorsk)",
      "se_SE": "Northern Sami (Sweden)",
      "no_NO": "Norwegian (Norway)",
      "ny_MW": "Nyanja (Malawi)",
      "uz_UZ": "O'zbek",
      "om_ET": "Oromoo (Ethiopia)",
      "om_KE": "Oromoo (Kenya)",
      "os_AZ": "Ossetic (Azerbaijan)",
      "os_GE": "Ossetic (Georgia)",
      "os_KZ": "Ossetic (Kazakhstan)",
      "os_RU": "Ossetic (Russia)",
      "os_TJ": "Ossetic (Tajikistan)",
      "os_TM": "Ossetic (Turkmenistan)",
      "os_UA": "Ossetic (Ukraine)",
      "os_UZ": "Ossetic (Uzbekistan)",
      "pl_PL": "Polski",
      "pl_UA": "Polski (Ukraine)",
      "pt_AO": "Português (Angola)",
      "pt_BR": "Português (Brasil)",
      "pt_CV": "Português (Cape Verde)",
      "pt_GW": "Português (Guinea-Bissau)",
      "pt_MZ": "Português (Mozambique)",
      "pt_PT": "Português (Portugal)",
      "pt_ST": "Português (São Tomé & Príncipe)",
      "pt_TL": "Português (Timor-Leste)",
      "ff_MR": "Pulaar (Mauritania)",
      "ff_NE": "Pulaar (Niger)",
      "ff_NG": "Pulaar (Nigeria)",
      "ff_SN": "Pulaar (Senegal)",
      "qu_PE": "Qhichwa",
      "ty_PF": "Reo Tahiti",
      "ro_RO": "Română",
      "ro_UA": "Română (Ukraine)",
      "rm_CH": "Rumantsch",
      "gd_GB": "Scottish Gaelic (United Kingdom)",
      "sq_AL": "Shqip",
      "sk_HU": "Slovenčina (Hungary)",
      "sk_SK": "Slovenčina (Slovakia)",
      "sl_SI": "Slovenščina",
      "so_DJ": "Soomaali (Djibouti)",
      "so_ET": "Soomaali (Ethiopia)",
      "so_KE": "Soomaali (Kenya)",
      "so_SO": "Soomaaliga",
      "nr_ZA": "South Ndebele (South Africa)",
      "st_LS": "Southern Sotho (Lesotho)",
      "fi_FI": "Suomi",
      "fi_SE": "Suomi (Sweden)",
      "sv_SE": "Svenska",
      "sv_FI": "Svenska (Finland)",
      "sv_AX": "Svenska (Åland Islands)",
      "ss_ZA": "Swati (South Africa)",
      "ss_SZ": "Swati (Swaziland)",
      "sg_CF": "Sängö (Central African Republic)",
      "vi_VN": "Tiếng Việt",
      "ts_ZA": "Tsonga (South Africa)",
      "tn_BW": "Tswana (Botswana)",
      "tn_ZA": "Tswana (South Africa)",
      "tk_TM": "Turkmen (Turkmenistan)",
      "tr_TR": "Türkçe",
      "tr_BG": "Türkçe (Bulgaria)",
      "tr_CY": "Türkçe (Cyprus)",
      "tr_MD": "Türkçe (Moldova)",
      "ug_AF": "Uyghur (Afghanistan)",
      "ug_KZ": "Uyghur (Kazakhstan)",
      "ug_KG": "Uyghur (Kyrgyzstan)",
      "ug_TJ": "Uyghur (Tajikistan)",
      "ug_TR": "Uyghur (Turkey)",
      "ug_UZ": "Uyghur (Uzbekistan)",
      "ve_ZA": "Venda (South Africa)",
      "ve_ZW": "Venda (Zimbabwe)",
      "wa_BE": "Walloon (Belgium)",
      "fy_DE": "Western Frisian (Germany)",
      "wo_GM": "Wolof (Gambia)",
      "wo_MR": "Wolof (Mauritania)",
      "wo_SN": "Wolof (Senegal)",
      "xh_LS": "Xhosa (Lesotho)",
      "yi_AU": "Yiddish (Australia)",
      "yi_BY": "Yiddish (Belarus)",
      "yi_BE": "Yiddish (Belgium)",
      "yi_CA": "Yiddish (Canada)",
      "yi_EE": "Yiddish (Estonia)",
      "yi_IL": "Yiddish (Israel)",
      "yi_LV": "Yiddish (Latvia)",
      "yi_LT": "Yiddish (Lithuania)",
      "yi_MD": "Yiddish (Moldova)",
      "yi_PL": "Yiddish (Poland)",
      "yi_RO": "Yiddish (Romania)",
      "yi_RU": "Yiddish (Russia)",
      "yi_ZA": "Yiddish (South Africa)",
      "yi_UA": "Yiddish (Ukraine)",
      "yi_US": "Yiddish (United States)",
      "en_UK": "en_UK",
      "xh_ZA": "isiXhosa",
      "zu_ZA": "isiZulu",
      "yo_NG": "Èdè Yorùbá (Nigeria)",
      "is_IS": "Íslenska (Iceland)",
      "cs_CZ": "Čeština",
      "el_GR": "Ελληνικά",
      "el_CY": "Ελληνικά (Cyprus)",
      "uz_AF": "Ўзбек (Afghanistan)",
      "be_BY": "Беларуская",
      "bg_BG": "Български",
      "mk_MK": "Македонски (Македонски)",
      "mn_MN": "Монгол",
      "ru_RU": "Русский",
      "sr_BA": "Српски (Bosnia & Herzegovina)",
      "sr_HU": "Српски (Hungary)",
      "sr_RS": "Српски (Serbia)",
      "uk_UA": "Українська",
      "ru_UA": "русский (Ukraine)",
      "tt_RU": "татарча",
      "tg_TJ": "тоҷикӣ",
      "uk_MD": "українська (Moldova)",
      "kk_KZ": "Қазақша",
      "hy_AM": "Հայերեն",
      "yi_DE": "ייִדיש (German)",
      "he_IL": "עברית‏",
      "ur_PK": "اردو",
      "ur_IN": "اردو (India)",
      "ar_DZ": "العربية (Algeria)",
      "ar_AR": "العربية (Argentina)",
      "ar_BH": "العربية (Bahrain)",
      "ar_TD": "العربية (Chad)",
      "ar_EG": "العربية (Egypt)",
      "ar_IN": "العربية (India)",
      "ar_IQ": "العربية (Iraq)",
      "ar_IL": "العربية (Israel)",
      "ar_JO": "العربية (Jordan)",
      "ar_KW": "العربية (Kuwait)",
      "ar_LB": "العربية (Lebanon)",
      "ar_LY": "العربية (Libya)",
      "ar_MR": "العربية (Mauritania)",
      "ar_MA": "العربية (Morocco)",
      "ar_OM": "العربية (Oman)",
      "ar_PS": "العربية (Palestinian Territories)",
      "ar_QA": "العربية (Qatar)",
      "ar_SO": "العربية (Somalia)",
      "ar_SD": "العربية (Sudan)",
      "ar_SY": "العربية (Syria)",
      "ar_TN": "العربية (Tunisia)",
      "ar_AE": "العربية (United Arab Emirates)",
      "ar_YE": "العربية (Yemen)",
      "ar_SA": "العربية (السعودية)",
      "fa_IR": "فارسی",
      "ps_AF": "پښتو",
      "ne_NP": "नेपाली",
      "ne_BT": "नेपाली (Bhutan)",
      "ne_IN": "नेपाली (India)",
      "mr_IN": "मराठी",
      "sa_IN": "संस्कृतम्",
      "hi_IN": "हिन्दी",
      "as_IN": "অসমীয়া (India)",
      "bn_SG": "বাংলা (Singapore)",
      "bn_IN": "বাংলা (ভারত)",
      "bn_BD": "বাংলা(বাংলাদেশ)",
      "pa_IN": "ਪੰਜਾਬੀ (ਭਾਰਤ ਨੂੰ)",
      "gu_IN": "ગુજરાતી",
      "or_IN": "ଓଡ଼ିଆ",
      "ta_IN": "தமிழ்",
      "ta_SG": "தமிழ் (Singapore)",
      "te_IN": "తెలుగు",
      "kn_IN": "ಕನ್ನಡ (India)",
      "ml_IN": "മലയാളം",
      "si_LK": "පළාත",
      "si_TH": "සිංහල (Thailand)",
      "th_TH": "ภาษาไทย (ประเทศไทย)",
      "bo_CN": "པོད་སྐད་ (China)",
      "my_MM": "ဗမာ (Myanmar (Burma))",
      "ka_GE": "ქართული",
      "ti_ER": "ትግርኛ (Eritrea)",
      "ti_ET": "ትግርኛ (Ethiopia)",
      "am_ET": "አማርኛ (Ethiopia)",
      "ck_US": "ᏣᎳᎩ (tsalagi)",
      "km_KH": "ភាសាខ្មែរ",
      "gx_GR": "Ἑλληνική ἀρχαία",
      "zh_MO": "中文 (Macau SAR China)",
      "zh_CN": "中文（中国）",
      "zh_TW": "中文（台灣）",
      "zh_SG": "中文（新加坡）",
      "zh_HK": "中文（香港）",
      "ja_JP": "日本語",
      "ii_CN": "ꆈꌠꉙ (China)",
      "ko_KP": "한국어 (North Korea)",
      "ko_KR": "한국어 (韩国)"
    },
    "autocompletePicker": {
      "placeholder": "Sélectionner...",
      "searchPlaceholder": "Écrire au moins 3 caractères pour rechercher...",
      "noResults": "Aucun résultat pour cette recherche"
    },
    "invalidPhoneNumber": "Le numéro de téléphone est invalide",
    "yes": "Oui",
    "no": "Non",
    "cancel": "Annuler",
    "validate": "Valider"
  },
  "authScreen": {
    "welcome": "Bienvenue sur votre portail bancaire",
    "login": "Se connecter",
    "createAccount": "Créer un compte",
    "appstoreLink": "Appstore",
    "playstoreLink": "Playstore",
    "goToCompanyPortalQuestion": "Vous êtes une entreprise ?",
    "goToCompanyPortalLink": "Espace entreprises",
    "goToPartnersPortalQuestion": "Vous êtes un commerçant ?",
    "goToPartnersPortalLink": "Espace commerçants",
    "goToAgentPortalQuestion": "Vous êtes un agent ?",
    "goToAgentPortalLink": "Espace agents",
    "storesAvailable": "Disponible aussi sur",
    "disconnectedDueToInactivity": "Vous avez été déconnecté suite à une inactivité prolongée",
    "authenticationFailure": {
      "title": "\u00c9chec de l'authentification"
    }
  },
  "connectScreen": {
    "title": "Bienvenue sur [emph]Skaleet[/emph]",
    "subtitle": "Veuillez entrer votre numéro de téléphone pour vous connecter",
    "lostPhoneButton": "Numéro de téléphone oublié ?",
    "connectButton": "Se connecter",
    "registerQuestion": "Vous n'avez pas de compte ?",
    "registerButton": "Créer un compte",
    "errorTitle": "Une erreur est survenue",
    "noAccountError": "Nous n'avons pu trouver de compte pour le {phoneNumber}. Vous pouvez créer un compte pour vous connecter.",
    "pincodeTitle": "Code [emph]secret[/emph]",
    "otpTitle": "Vérification en [emph]deux étapes[/emph]",
    "noCodeQuestion": "Code non reçu ?",
    "contactSupportLink": "Veuillez contacter le support.",
    "contactSupport": {
      "title": "Vous n'avez plus [emph]accès au téléphone[/emph] rattaché à votre compte ?",
      "message": "Pour modifier vos coordonnées, veuillez prendre contact avec notre équipe support qui vous indiquera la marche à suivre.",
      "byPhone": "Nous contacter par téléphone",
      "byEmail": "Nous contacter par email",
      "done": "Terminer"
    }
  },
  "errorScreen": {
    "subTitle": "Trop de demandes",
    "title": "La tentative de connexion a échoué",
    "message": "Nous ne pouvons pas traiter votre demande de connexion pour le moment.\n\n Veuillez réessayer plus tard.",
    "button": "Revenir à la page de connexion"
  },
  "onboardingStatusCard": {
    "title": "Plus d'informations sont nécessaires concernant votre dossier d'inscription",
    "subtitle": "Lorem ipsum dolor sit amet",
    "button": "Compléter mon dossier"
  },
  "onboardingDelegateErrorStatusCard": {
    "title": "Impossible d’accéder au formulaire d’inscription en tant qu’utilisateur délégué. Les fonctionnalités disponibles sont limitées. Le propriétaire du compte doit compléter son inscription."
  },
  "onboardingScreen": {
    "progressLabel": "Étape {step} sur {count} : {label}",
    "initialStepTitle": "Bienvenue sur [emph]MWallet.[/emph]",
    "resumptionStepTitle": "Dites nous en plus sur [emph]vous[/emph]",
    "successIntroduction": "Félicitations",
    "successTitle": "Votre inscription est finalisée",
    "successButton": "Commencer !",
    "initiationSuccessTitle": "Votre inscription a été initiée avec succès.",
    "initiationSuccessButton": "Se connecter",
    "initiationSuccessMsg": "Un code secret a été envoyé à {phone}",
    "getStartedButton": "Commencer",
    "cancelButton": "Annuler",
    "back": "Précédent",
    "next": "Suivant",
    "save": "Enregistrer",
    "submit": "Soumettre",
    "taxedNumberError": {
      "undefined": "Erreur : Veuillez saisir un numéro de téléphone valide.",
      "PREMIUM_RATE": "Erreur: Les numéros à tarif majoré ne peuvent pas être utilisés pour l’enregistrement d’un compte. Veuillez fournir un numéro de téléphone mobile ou fixe.",
      "TOLL_FREE": "Erreur: Les numéros gratuits ne sont pas pris en charge pour la vérification du compte. Veuillez utiliser un numéro de téléphone mobile ou fixe standard.",
      "SHARED_COST": "Erreur: Les numéros avec facturation à frais partagés ne sont pas autorisés pour la création de compte. Veuillez saisir un numéro de mobile ou de ligne fixe.",
      "VOIP": "Erreur: Les numéros de téléphone Internet ne sont pas éligibles à l’enregistrement. Veuillez utiliser un numéro de téléphone mobile ou fixe standard.",
      "PERSONAL_NUMBER": "Erreur: Les numéros personnels connectés par satellite ne sont pas acceptés. Veuillez utiliser un numéro de téléphone mobile ou fixe.",
      "PAGER": "Erreur: Les appareils de téléavertisseur ne sont pas valides pour la création de compte. Veuillez fournir un numéro de téléphone mobile ou fixe accepté.",
      "UAN": "Erreur : Les numéros d'accès universels (UAN) ne peuvent pas être utilisés pour l'enregistrement. Veuillez saisir un numéro de téléphone mobile ou fixe valide.",
      "VOICEMAIL": "Erreur : Les numéros dédiés aux services de messagerie vocale ne peuvent pas être utilisés. Veuillez saisir un numéro de mobile ou de téléphone fixe pouvant recevoir directement des appels."
    },
    "formLabels": {
      "selectDistributorDuringOnboarding": "Code de distributeur d'intégration",
      "selectDistributorDuringOnboardingHelperText": "Veuillez renseigner l’ID fourni par votre référent carte.",
      "firstName": "Prénom",
      "lastName": "Nom",
      "phoneNumber": "Téléphone",
      "email": "Email"
    }
  },
  "registerScreen": {
    "progressLabel": "Étape {step} sur {count} : {label}",
    "firstStepTitle": "Bienvenue !",
    "firstStepSubtitle": "Veuillez entrer votre numéro de téléphone pour vous inscrire",
    "connectQuestion": "Vous possédez déjà un compte ?",
    "connectButton": "Se connecter",
    "phoneNumberLabel": "Numéro de téléphone",
    "back": "Précédent",
    "next": "Suivant",
    "finish": "Terminer",
    "otpTitle": "Verification du téléphone",
    "otpLabel": "Veuillez entrer le code reçu par SMS",
    "nbPicturesForDocument": {
      "one": "une photo minimum",
      "others": "{count} photos minimum"
    },
    "pictureInputFilled": "Un fichier",
    "pictureInputEmpty": "Choisir ou déposer une photo",
    "addPictureButton": "Ajouter une page",
    "eulaTitle": "Conditions d'utilisations",
    "eulaFirstDescription": "Veuillez accepter les conditions d'utilisation afin de vous créer un compte.",
    "eulaSecondDescription": "Vous les trouverez en cliquant sur le lien suivant :",
    "eulaLink": "Conditions d'utilisations",
    "eulaAcceptLabel": "J'accepte les conditions d'utilisations",
    "createMyAccountButton": "Créer mon compte",
    "finishTitleLabel": "Félicitations !",
    "finishLabel": "Votre compte a été créé avec succès.",
    "pincodeLabel": "Votre code secret",
    "pincodeStepTitle": "Votre code [emph]secret.[/emph]",
    "quitConfirmMessage": "Vous êtes sur le point de quitter l'inscription. Toutes les données remplis seront perdus. Êtes vous sur de vouloir quitter la page ?",
    "cancel": "Annuler",
    "message": "Vous devez désormais choisir un code secret à quatre chiffres pour votre compte. Surtout, ne le communiquez à personne, il est strictement confidentiel.",
    "doNotForgetWarning": "Attention, veillez à bien le conserver : vous en aurez besoin pour vous authentifier et valider toutes vos opérations. ",
    "successSnackbar": "Votre code secret a bien été validé",
    "newCodeLabel": "Entrez votre nouveau code",
    "newCodeConfirmationLabel": "Confirmez votre nouveau code",
    "newCodesDontMatchError": "Les codes entrés ne correspondent pas",
    "noEulaTitle": "Confirmation de la création de compte",
    "noEulaFirstDescription": "Vous y êtes presque !",
    "noEulaSecondDescription": "En cliquant sur le bouton suivant, la création de votre compte sera effective. On y va ?",
    "privacyTitle": "Politique de confidentialité",
    "privacyFirstDescription": "Veuillez accepter la politique de confidentialité afin de vous créer un compte.",
    "privacySecondDescription": "Vous la trouverez en cliquant sur le lien suivant :",
    "privacyLink": "Politique de confidentialité",
    "privacyAcceptLabel": "J'accepte la politique de confidentialité",
    "noPrivacyTitle": "Confirmation de la création de compte",
    "noPrivacyFirstDescription": "Vous y êtes presque !",
    "noPrivacySecondDescription": "En cliquant sur le bouton suivant, la création de votre compte sera effective. On y va ?"
  },
  "accountOwner": {
    "male": "M. {name}",
    "female": "Mme {name}",
    "other": "{name}",
    "none": "{name}"
  },
  "connectedUserGreetings": {
    "withName": "Bonjour, \n[emph]{name}[/emph] !",
    "withoutName": "Bonjour !"
  },
  "clientStatus": {
    "clientBlockedMessage": "Votre compte est bloqué.",
    "clientBlockedLink": "Plus d'informations...",
    "clientPendingMessage": "Votre dossier est en cours de traitement."
  },
  "navigationMenu": {
    "accountLink": "Comptes",
    "transferLink": "Transferts",
    "payBillLink": "Services",
    "cardsLink": "Cartes",
    "savingsLink": "Épargne",
    "loansLink": "Prêts",
    "contactLink": "Aide et assistance",
    "settingsLink": "Voir mon profil",
    "disconnectButton": "Se déconnecter"
  },
  "transfertsSubmenu": {
    "allTransfer": "Effectuer un virement",
    "externalAccount": "Compte externe",
    "recipients": "Bénéficiaires",
    "cashTransfer": "Transferts en attente",
    "upcomingTransfers": "Virements permanents",
    "recharge": "Recharger mon compte",
    "transactionCode": "Code de transaction",
    "simpleTransfer": "Par numéro de téléphone"
  },
  "savingsSubmenu": {
    "mySavingsAccount": "Mes comptes d'épargne",
    "deposits": "Virements et dépôts",
    "createSavingsAccount": "Créer un compte d'épargne"
  },
  "simpleTransfer": {
    "newTransfer": "Nouveau transfert",
    "title": "Par numéro de téléphone",
    "searchRecipient": "Rechercher un destinataire",
    "invalidPhoneNumber": "Le numéro de téléphone fourni n'est pas reconnu"
  },
  "accountPendingVerification": {
    "title": "Vérification de compte",
    "subtitle": "Votre demande de création de compte est en train d'être étudiée. Veuillez réessayer ultérieurement.",
    "retryButton": "Réessayer"
  },
  "accountsList": {
    "title": "Mes comptes.",
    "loadAccountsError": "Une erreur est survenue lors du chargement des comptes.",
    "loadAccountsRetry": "Réessayer",
    "showAllAccountsLink": "Voir tous mes comptes",
    "noAccountAvailable": "Aucun compte n'est disponible"
  },
  "recipient": {
    "title": "Effectuer un virement",
    "subtitle": "À qui voulez-vous transférer votre argent ?",
    "section": "Mes bénéficiaires",
    "noRecipientsAvailable": "Aucun destinataire n'est disponible",
    "noRecipientsMessage": "Ajoutez des bénéficiaires pour transferer de l’argent.",
    "addRecipientButton": "Ajouter un bénéficiaire",
    "transferTo": "Transfert vers {name}",
    "searchInputPlaceholder": "Rechercher un bénéficiaire",
    "selector": {
      "beneficiaries": "Bénéficiaires",
      "externalAccounts": "Comptes externes"
    },
    "deletePrompt": {
      "title": "Supprimer",
      "message": "Voulez-vous vraiment supprimer le destinataire {name} ?",
      "recipient": "un bénéficiaire",
      "externalAccountTitle": "Supprimer un compte externe",
      "externalAccountMessage": "Êtes-vous sûr de vouloir supprimer ce compte externe ?",
      "cancelButton": "Annuler",
      "confirmButton": "OK"
    },
    "externalAccounts": {
      "title": "Mes comptes externes",
      "addButton": "Ajouter un compte externe",
      "noneAvailableTitle": "Aucun compte externe actuellement",
      "noneAvailableMessage": "Ajoutez un compte bancaire externe pour transferer de l’argent.",
      "pendingExternalAccountMessage": "Votre compte externe est en cours de validation."
    }
  },
  "addRecipient": {
    "selector": {
      "iban": "IBAN",
      "phone": "Téléphone",
      "account": "N° compte"
    },
    "unauthorized": "Vous n'êtes pas autorisé à accèder à cette fonctionnalité",
    "title": "Ajouter",
    "subtitle": "un bénéficiaire",
    "externalAccountSubtitle": "un compte externe",
    "nameFieldPlaceholder": "Nom",
    "phoneFieldPlaceholder": "Numéro de téléphone",
    "ibanFieldPlaceholder": "IBAN",
    "accountFieldPlaceholder": "Numéro de compte",
    "bicFieldPlaceholder": "BIC",
    "bankFieldPlaceholder": "Banque / BIC",
    "createButton": "Ajouter",
    "otpAdditionalText": "Ajout du bénéficiaire {name}",
    "otpAdditionalTextWithBank": "Ajout du bénéficiaire {name} de la banque {bankName}",
    "addConfirmationMessage": "Le bénéficiaire {name} a bien été ajouté",
    "searchBank": {
      "title": "Banque / BIC",
      "bankPlaceholder": "Tapez au moins {num} lettres pour rechercher",
      "firstSearchMessage": "Faites une première recherche pour obtenir des résultats",
      "noBankMessage": "Pas de banque correspondante trouvée",
      "errorMessage": "Une erreur est survenue lors du chargement de la liste des banques.",
      "retryButton": "Réessayer"
    }
  },
  "editRecipient": {
    "title": "Modifier",
    "subtitle": "un destinataire",
    "externalAccountSubtitle": "le compte externe",
    "saveButton": "Enregistrer"
  },
  "account": {
    "accountId": "N°{id}",
    "accountType": {
      "current": "Compte courant",
      "savings": "Compte épargne",
      "dedicated": "Compte dédié",
      "moneyPot": "Cagnotte",
      "technical": "Compte technique"
    },
    "detail": "Détails"
  },
  "accountStatus": {
    "opened": "Ouvert",
    "closed": "Clos",
    "blocked": "Bloqué",
    "debitBlocked": "Débit interdit",
    "creditBlocked": "Crédit interdit"
  },
  "accountTransactions": {
    "title": "Mes transactions.",
    "emptyTransactionList": "Aucune transaction pour l'instant",
    "loadTransactionsError": "Une erreur est survenue lors du chargement des transactions.",
    "loadMoreTransactionsError": "Une erreur est survenue lors du chargement des transactions.",
    "loadMoreTransactionsRetry": "Réessayer",
    "unjustifiedTransactions": {
      "title": {
        "one": "Vous avez {count} transaction en attente de justificatif",
        "other": "Vous avez {count} transactions en attente de justificatif",
        "more": "Vous avez {count} transactions ou plus en attente de justificatif"
      },
      "description": "Certaines de vos transactions attendent des documents de justification."
    },
    "unjustifiedTransactions2": {
      "title": {
        "one": "Vous avez {count} transaction en attente de justificatif",
        "other": "Vous avez {count} transactions en attente de justificatif",
        "more": "Vous avez {count} transactions ou plus en attente de justificatif"
      },
      "description": "Certaines de vos transactions attendent des documents de justification."
    },
    "unqualifiedTransactions": {
      "title": {
        "one": "Vous avez {count} transaction en attente de qualification",
        "other": "Vous avez {count} transactions en attente de qualification",
        "more": "Vous avez {count} transactions en attente de qualification"
      },
      "description": "Certaines de vos transactions attendent une qualification."
    },
    "authorizationHolds": {
      "title": {
        "one": "Vous avez {count} transaction en attente",
        "other": "Vous avez {count} transactions en attente",
        "more": "Vous avez {count} transactions en attente"
      },
      "description": "L'argent a été débité de votre compte mais n'est pas encore reçu par le bénéficiaire."
    },
    "expenseUnjustified": "Non justifié",
    "previousPeriodButtonLabel": "Période précédente",
    "nextPeriodButtonLabel": "Période suivante"
  },
  "authorizationHolds": {
    "cashoutLabel": "Retrait",
    "paymentLabel": "Paiement",
    "externalToAccountLabel": "Système externe vers compte",
    "accountToExternalLabel": "Compte vers système externe",
    "externalFromAccountLabel": "Système externe depuis compte",
    "accountRechargeLabel": "Rechargement compte",
    "onUsCreditTransferLabel": "Virement on-us",
    "managementFeesLabel": "Frais de gestion"
  },
  "accountingTransactionDetailsModal": {
    "detailsTitle": "Détails",
    "transferLabel": "Transfert",
    "description": "Description : {description}",
    "transaction": "Transaction n°{id}",
    "reference": "Référence : {id}",
    "feeLabel": "Frais",
    "commissionLabel": "Commission",
    "taxLabel": "Taxes",
    "discountLabel": "Réduction",
    "interestLabel": "Intérêts",
    "amountLabel": "Total",
    "balanceLabel": "Solde après transaction",
    "additionalInformationTitle": "Informations additionnelles",
    "geolocationTitle": "Localisation",
    "externalIdLabel": "Référence de transaction du partenaire : {externalId}",
    "justifyButtonLabel": "Justifier ma dépense",
    "refundButtonLabel": "Demander un remboursement",
    "verifyJustificationsButtonLabel": "Vérifier mes justificatifs",
    "justificationFollowUpTitle": "Suivi de justification",
    "documentsSubmitted": "Justificatifs déposés",
    "validationInProgress": "En attente de validation"
  },
  "authorizationHoldsDetailsModal": {
    "pending": "En attente",
    "detailsTitle": "Détails",
    "transferLabel": "Transfert",
    "reference": "Transaction n°{id}",
    "amountLabel": "Total"
  },
  "allAccountsScreen": {
    "goBackClassicViewButton": "Retour sur la vue classique",
    "noAccountAvailable": "Aucun compte n'est disponible"
  },
  "recipientDetail": {
    "information": "Informations.",
    "accountDetail": "RIB/Domiciliation.",
    "transferMoneyButton": "Envoyer de l'argent",
    "dedicatedTransferButton": "Transaction cadeau"
  },
  "amountSelection": {
    "amountLabel": "Montant",
    "currencyLabel": "Devise :",
    "descriptionLabel": "Message :",
    "descriptionButton": "Ajouter une description",
    "descriptionPlaceholder": "Ajouter un message",
    "simpleTransferNameLabel": "Nom",
    "simpleTransferNamePlaceholder": "Ajouter un nom",
    "simpleTransferPhoneLabel": "Téléphone :",
    "simpleTransferPhonePlaceholder": "Ajouter un téléphone",
    "sendButton": "Envoyer",
    "since": "Depuis",
    "to": "Vers",
    "choose_source_account": "Sélectionnez le compte à débiter",
    "choose_destination_account": "Sélectionnez le compte à créditer",
    "my_accounts": "Mes comptes",
    "my_external_account": "Compte externe",
    "my_recipients": "Mes bénéficiaires",
    "network": "Réseau interbancaire",
    "load_more": "Charger plus",
    "convertedAmountLabel": "Montant converti",
    "foreignCurrencyPlaceholder": "Devise",
    "creditorAddress": {
      "addressLabel": "Adresse",
      "countryLabel": "Pays",
      "buildingNumber": "N°",
      "streetName": "Rue",
      "postCode": "Code postal",
      "townName": "Ville",
      "country": "Pays"
    }
  },
  "acceptorSelection": {
    "title": "Sélection d'un commerçant",
    "selectionButton": "Sélectionner"
  },
  "dedicatedTransferAmount": {
    "title": "Montant du cadeau",
    "subtitle": "Point de transaction : {code}"
  },
  "dedicatedTransferSummary": {
    "recepient": "Bénéficiaire",
    "account": "Compte débité",
    "amount": "Montant maximum",
    "transactionPoint": "Point de transaction",
    "expirationDate": "Date d’expiration",
    "done": "Terminer"
  },
  "pincodeConfirmation": {
    "label": "Entrez votre code secret pour continuer",
    "networkError": "Veuillez vérifier votre connexion internet avant de poursuivre",
    "loadKeyboardError": "Une erreur est survenue lors du chargement du clavier.",
    "loadKeyboardRetry": "Recharger le clavier",
    "forgetPincode": "J'ai oublié mon code secret"
  },
  "transferSummary": {
    "accountTransferMode": "Vers compte",
    "cashTransferMode": "Vers cash",
    "summaryTitle": "Succès",
    "accountSummarySubtitle": "Détails du transfert",
    "cashSummarySubtitle": "Vous allez recevoir un message contenant un code à remettre à {recipient} ou consulter vos transferts en attente pour récupérer le code.",
    "summaryMiddleStep": "Transfert",
    "fees": "Frais : {amount}",
    "commission": "Commission : {amount}",
    "discount": "Réduction : {amount}",
    "tax": "Taxe : {amount}",
    "you": "Vous",
    "done": "Terminer",
    "customerInstructionSuccessMessage": "Votre virement N°{id} vers {recipient} a bien été effectué.",
    "recurringTransferSuccessMessage": "Le virement récurrent N°{id} à destination de {recipient} a été créé avec succès."
  },
  "billFormScreen": {
    "nextButton": "Suivant",
    "textInputPlaceholder": "Écrivez quelque chose…",
    "saveReferencePromptTitle": "Sauvegarder le {name}",
    "replaceReferencePromptTitle": "Remplacer le {name}",
    "saveReferencePromptMessage": "Voulez-vous sauvegarder le {name} pour ce facturier ?",
    "replaceReferencePromptMessage": "Voulez-vous remplacer le {name} pour ce facturier ?",
    "saveReferencePromptCancel": "Annuler",
    "saveReferencePromptConfirm": "Sauvegarder",
    "replaceReferencePromptConfirm": "Remplacer",
    "saveReferenceErrorMessage": "Le {name} n'a pas pu être enregistré",
    "docInputFilled": "Un fichier",
    "docInputEmpty": "Ajouter ou déposer une photo ici",
    "confirmationMessage": "Cliquez ci-dessous pour confirmer votre paiement de [b]{amount} [/b] à [b]{issuer}[/b]",
    "confirmButton": "Confirmer"
  },
  "billConfirmationScreen": {
    "fees": "Frais : [b]{amount}[/b]",
    "commission": "Commission : [b]{amount}[/b]",
    "discount": "Réduction : [b]{amount}[/b]",
    "tax": "Taxe : [b]{amount}[/b]"
  },
  "formError": {
    "invalidIBAN": "L'IBAN saisi est invalide",
    "invalidFileSize": "Votre fichier dépasse la taille maximale acceptée ({size})",
    "invalidFileFormat": "Le format de votre fichier n'est pas supporté. Formats acceptés : {formats}"
  },
  "billIssuersScreen": {
    "title": "Mes services.",
    "references": "Mes références",
    "searchInputPlaceholder": "Rechercher un partenaire",
    "emptyListLabel": "Aucun partenaire disponible",
    "loadBillIssuersError": "Une erreur est survenue lors du chargement des partenaires."
  },
  "billReferencesScreen": {
    "title": "Mes références",
    "services": "Retour à mes services",
    "subtitle": "Données sauvegardées pour vos paiements",
    "emptyRecipientList": "Aucune référence enregistrée",
    "deletePromptTitle": "Suppression de référence",
    "deletePromptMessage": "Voulez-vous vraiment supprimer la référence {name} ?",
    "deletePromptCancel": "Annuler",
    "deletePromptConfirm": "Confirmer",
    "deleteConfirmationMessage": "La référence {name} a bien été supprimée",
    "deleteErrorMessage": "La suppression de la référence {name} a échoué",
    "loadBillReferencesError": "Une erreur est survenue lors du chargement des références.",
    "lastUpdateDateTime": "Dernière mise à jour : {date} à {time}"
  },
  "otp": {
    "explanation": "Entrer le code à six chiffres que vous avez reçu au:",
    "sendBackCodeButton": "Renvoyer le code",
    "confirmOtpButton": "Confirmer",
    "cancelOtpButton": "Annuler",
    "goToTransferFormButton": "Accéder au formulaire de transfert",
    "backToRecipients": "Retour",
    "otpBlockedErrorMessage": "Vous avez effectué 3 tentatives incorrectes. Veuillez recommencer l'opération depuis le début"
  },
  "accountDetails": {
    "availableBalanceTitle": "Solde disponible",
    "balanceTitle": "Solde comptable",
    "balanceMessage": "Le solde comptable ne prend pas en compte les opérations en attente.",
    "accountStatus": {
      "blocked": "Ce compte est bloqué. Pour plus d’information veuillez contacter notre support.",
      "debitBlocked": "Les débits sont actuellement interdits sur ce compte. Pour plus d’informations veuillez contacter notre support.",
      "creditBlocked": "Les crédits sont actuellement interdits sur ce compte. Pour plus d’informations veuillez contacter notre support."
    },
    "accountName": "Nom du compte",
    "accountIdTitle": "Numéro de compte",
    "ibanTitle": "IBAN",
    "bicTitle": "BIC",
    "accessStatements": "Mes relevés de compte",
    "shareRIBLabel": "Partager mon RIB",
    "shareRIBMessage": "{firstName} {lastName} {postName}\nIBAN : {iban}\nBIC : {bic}",
    "dedicatedMessage": "Vous pouvez dépenser cette somme chez :",
    "clipboard": "{name} copié",
    "downloadBankDetails": "Coordonnées bancaires",
    "accountStartDate": "Date d'ouverture du compte",
    "maximumBoundaries": "Solde maximum",
    "initialAmountLabel": "Solde initial",
    "endDateLabel": "Date de fin",
    "durationLabel": "Durée",
    "monthsLabels": {
      "DAILY": "jours",
      "WEEKLY": "semaines",
      "MONTHLY": "mois",
      "QUARTERLY": "trimestres",
      "SEMESTER": "semestres",
      "ANNUALLY": "ans"
    },
    "rateValueLabel": "Taux appliqué",
    "indexCodeLabel": "Index",
    "rateStartDateLabel": "Date de début du taux",
    "accruedInterestsLabel": "Intérêts acquis",
    "startDateLabel": "Acquis depuis le",
    "forecastInterestsLabel": "Interêt total projeté",
    "initialAERLabel": "TRAAB",
    "success": "Modification enregistrée"
  },
  "accountsStatements": {
    "title": "Relevés de compte",
    "subtitle": "Tous mes relevés",
    "accountLabel": "Compte n°{id}",
    "accounts": "Comptes",
    "statements": "Relevés de compte disponibles",
    "emptyStatementList": "Aucun relevé de compte disponible",
    "cancel": "Annuler",
    "loadStatementsError": "Une erreur est survenue lors du chargement des relevés de compte.",
    "generateButton": "Générer"
  },
  "feesStatements": {
    "title": "Relevés de frais",
    "subtitle": "Tous mes relevés",
    "accountLabel": "Compte n°{id}",
    "accounts": "Comptes",
    "statements": "Relevés de frais disponibles",
    "emptyStatementList": "Aucun relevé de frais disponible",
    "cancel": "Annuler",
    "loadStatementsError": "Une erreur est survenue lors du chargement des relevés de frais."
  },
  "cardsList": {
    "title": "Mes cartes.",
    "loadCardsError": "Une erreur est survenue lors du chargement des cartes.",
    "loadCardsRetry": "Réessayer",
    "showAllCardsLink": "Voir toutes mes cartes",
    "statusFilterTitle": "Statut",
    "orderCard": "Commander une nouvelle carte",
    "refabricateButton": "Remplacer la carte",
    "infos": {
      "title": "Informations"
    },
    "details": {
      "title": "Voir le détail de la carte",
      "holder": "Titulaire de la carte",
      "number": "Numéro",
      "date": "Date d'expiration",
      "cvv": "CVV"
    }
  },
  "allCardsScreen": {
    "goBackClassicViewButton": "Retour sur la vue classique",
    "noCardAvailable": "Aucune carte n'est disponible"
  },
  "card": {
    "title": "CARTE",
    "virtual": "Virtuelle",
    "status": {
      "all": "Tous les statuts",
      "active": "Active",
      "pending": "En attente",
      "new": "Nouvelle",
      "expired": "Expirée",
      "blocked": "Bloquée",
      "opposed": "Opposée",
      "removed": "Retirée",
      "cancelled": "Annulée"
    },
    "feature": {
      "nfc": "Sans contact",
      "cashOperation": "Retrait",
      "foreignPayment": "Paiement à l'étranger",
      "onlinePayment": "Paiement en ligne",
      "eurWithdrawal": "Retrait en zone euro",
      "foreignWithdrawal": "Retrait à l'étranger",
      "paymentAuthorized": "Paiement autorisé",
      "eurPayment": "Paiement en zone euro",
      "foreignAll": "Paiement et retrait à l'étranger",
      "foreignOnlinePayment": "Paiement en ligne à l'étranger"
    },
    "pendingOperation": {
      "refabrication": "La carte en cours de refabrication",
      "codeReissuing": "Le code PIN de la carte est en cours de modification",
      "opposition": "La carte est en cours d'opposition",
      "blocking": "La carte est en cours de blocage",
      "unblocking": "La carte est en cours de déblocage",
      "activation": "La carte est en cours d'activation",
      "creation": "La carte est en cours de création",
      "limitsUpdate": "Les plafonds de la carte sont en cours de modifications",
      "featuresUpdate": "La fonctionnalité \"{feature}\" de la carte est en cours de modification",
      "unknownFeatureUpdate": "Une fonctionnalité de la carte est en cours de modification"
    }
  },
  "cardPincode": {
    "importantMessage": "Attention, veillez à bien conserver ce code:\nVous en aurez besoin pour valider les opérations avec votre carte.",
    "previous": "Changer",
    "next": "Suivant",
    "errorCodesMismatch": "Les codes ne correspondent pas"
  },
  "cardOptions": {
    "title": "Réglages de paiements",
    "outstandings": {
      "navigateButton": "Mes plafonds",
      "title": "Mes plafonds",
      "subtitle": "Visionnez les plafonds de votre carte",
      "paymentHeader": "Plafonds de paiement",
      "cashoutHeader": "Plafonds de retrait",
      "slidingPeriod": "période glissante de {num} {unit}",
      "fixedPeriod": "du {startDate} au {endDate}",
      "used": "Consommé",
      "available": "Disponible",
      "maxAmount": "Montant max",
      "maxOperations": "Opérations max",
      "selector": {
        "payments": "Paiements",
        "cashouts": "Retraits"
      },
      "noOutstandingLabel": "Pas de plafonds configurés",
      "errorMessage": "Une erreur est survenue lors du chargement des plafonds.",
      "retryButton": "Réessayer",
      "durationUnit": {
        "hour": "heure",
        "hours": "heures",
        "day": "jour",
        "days": "jours",
        "week": "semaine",
        "weeks": "semaines",
        "month": "mois",
        "months": "mois",
        "year": "an",
        "years": "ans"
      },
      "updateButtonLabel": "Modifier le plafond",
      "modal": {
        "title": "Modifier mon plafond",
        "inputLabel": "Définir une limite",
        "inputLabelUnit": {
          "day": "journalière",
          "week": "hebdomadaire",
          "month": "mensuelle",
          "year": "annuelle",
          "all": "globale"
        },
        "maximumLabel": "Maximum",
        "submitButtonLabel": "Définir une limite",
        "validationMsg": "Votre modification a été prise en compte"
      }
    },
    "refabricate": {
      "modal": {
        "title": "Confirmation",
        "message": "Êtes-vous sûr.e de vouloir remplacer cette carte ?",
        "cardNumber": "{cardNumber} ",
        "submitButtonLabel": "Confirmer"
      },
      "motiveLabel": "Sélectionnez le motif",
      "motivesOptions": {
        "DAMAGED": "Carte endommagée",
        "STOLEN": "Carte volée",
        "LOST": "Carte perdue",
        "ABUSE": "Utilisation frauduleuse"
      }
    },
    "oppositionCard": {
      "opposeButton": "Faire opposition",
      "title": "Pour faire opposition et bloquer définitivement votre carte, choisissez un motif ci-dessous:",
      "reasonLost": "Carte perdue",
      "reasonStolen": "Carte volée",
      "reasonNotDistributed": "Carte non distribuée",
      "opposeConfirmButton": "Confirmer",
      "opposeCancelButton": "Annuler",
      "opposePromptTitle": "Faire opposition",
      "opposePromptMessage": "Cette opération est irréversible. Êtes-vous sûr de vouloir faire opposition ?"
    },
    "deactivateCard": {
      "deactivateButton": "Résilier la carte",
      "title": "Cette opération est irréversible. Êtes-vous sûr de vouloir résilier ?",
      "deactivateConfirmButton": "Confirmer",
      "deactivateCancelButton": "Annuler",
      "deactivatePromptTitle": "Résilier",
      "deactivatePromptMessage": "Cette opération est irréversible. Êtes-vous sûr de vouloir résilier ?"
    },
    "updateCardPincode": {
      "updateButton": "Modifier code PIN",
      "title": "Modification du Code PIN",
      "definePincodeMessage": "Choisissez un nouveau code PIN à quatre chiffres pour votre carte. Surtout, ne le communiquez à personne, il est strictement confidentiel.",
      "confirmPincodeMessage": "Veuillez confirmer le code PIN de votre carte pour continuer.",
      "successTitle": "Félicitations !",
      "successSubtitle": "Le code PIN a été mis à jour !",
      "failureTitle": "Erreur",
      "failureSubtitle": "Veuillez nous excuser.",
      "failureDescription": "Il semble y avoir eu une erreur lors de la mise à jour du code PIN de votre carte. Veuillez réessayer plus tard ou contacter notre service client.",
      "confirmationPopup": {
        "message": "Si vous quitter l'édition du code PIN il ne sera pas mis à jour.\nQuitter l'édition ?"
      }
    },
    "activationCard": {
      "title": "Activer ma carte",
      "activateButton": "Activer",
      "defaultActivationMessage": "Renseignez l'identifiant à 9 chiffres présent à l'arrière de votre carte.",
      "identifier": "Votre identifiant à 9 chiffres",
      "next": "Suivant",
      "confirm": "Activer",
      "inputError": "Erreur de saisie, veuillez recommencer.",
      "stepPincode": {
        "title": "Code PIN",
        "definePincodeMessage": "Vous devez désormais choisir un code PIN à quatre chiffres pour votre carte. Surtout, ne le communiquez à personne, il est strictement confidentiel.",
        "confirmPincodeMessage": "Veuillez confirmer le code PIN de votre carte pour continuer."
      }
    },
    "switch": {
      "lockCard": "Verrouillage temporaire",
      "unlockCard": "Déverrouillage",
      "showDetails": "Voir le détail",
      "hideDetails": "Masquer le détail"
    },
    "featuresSwitchesOverlay": {
      "cardLocked": "Carte verrouillée"
    },
    "cardOpposedMessage": "Vous avez fait opposition sur cette carte, vous ne pouvez plus accéder à ses options."
  },
  "accountBlockedScreen": {
    "title": "Compte bloqué",
    "multipleAttemptBlockedReason": {
      "subtitle": "Trois tentatives de code secret erronées",
      "message": "Malheureusement, votre compte a été bloqué à cause de trois tentatives de code secret erronées. Veuillez contacter notre support {contacts}pour réinitialiser votre code secret."
    },
    "defaultBlockedReason": {
      "message": "Malheureusement, votre compte a été bloqué. Veuillez contacter notre support {contacts}pour réinitialiser votre code secret."
    },
    "contactsPhoneAndEmail": "au {contactPhone} ou par mail à {contactEmail} ",
    "contactsPhone": "au {contactPhone} ",
    "contactsEmail": "par mail à {contactEmail} ",
    "button": "OK, j’ai compris"
  },
  "bankContactScreen": {
    "title": "Ma banque.",
    "question": "Besoin d'aide ?",
    "addressContact": "Nous trouver",
    "phoneContact": "Nous appeler",
    "urlContact": "En savoir plus",
    "emailContact": "Nous écrire"
  },
  "pincodeChangeFlow": {
    "currentCodeLabel": "Entrez votre code actuel",
    "newCodeLabel": "Entrez votre nouveau code",
    "newCodeConfirmationLabel": "Confirmez votre nouveau code",
    "newCodesDontMatchError": "Les codes entrés ne correspondent pas",
    "success": "Votre code a été changé avec succès"
  },
  "pincodeChangeScreen": {
    "title": "Changement de code",
    "subtitle": "Changez votre code secret pour plus de sécurité",
    "message": "Il est nécessaire que vous changiez votre code secret avant d'effectuer cette opération",
    "messageAfterAuthentication": "Vous devrez changer votre code secret avant d'effectuer de nouvelles opérations",
    "changePincodeButton": "Changer mon code secret",
    "laterButton": "Plus tard",
    "done": "Terminer"
  },
  "clientInformation": {
    "title": "Mes informations",
    "birthDateTitle": "Date de naissance",
    "birthPlaceTitle": "Lieu de naissance",
    "addressTitle": "Adresse",
    "phoneTitle": "Téléphone",
    "emailTitle": "Email",
    "emailSuccess": "Vous avez reçu un e-mail de confirmation"
  },
  "cashTransferScreen": {
    "title": "Transferts en attente",
    "subtitle": "Annuler ou récupérer votre argent",
    "receivedSelector": "Reçus",
    "sentSelector": "Envoyés",
    "recipient": "Envoyé à : ",
    "sender": "Envoyé par : ",
    "emptyReceivedList": "Aucun transfert reçu",
    "emptySentList": "Aucun transfert envoyé",
    "receivedTransferDefaultTitle": "Transfert reçu",
    "sentTransferDefaultTitle": "Transfert envoyé",
    "codeLabel": "Code : {code}",
    "cancelCashTransferButton": "Annuler le transfert",
    "retrieveCashTransferButton": "Envoyer sur mon compte",
    "loadCashTransfersError": "Une erreur est survenue lors du chargement des transferts vers cash.",
    "loadMoreCashTransfersError": "Une erreur est survenue lors du chargement des transferts vers cash.",
    "loadMoreCashTransfersRetry": "Réessayer",
    "nextButton": "Suivant",
    "invalidCode": "Le code saisi est invalide",
    "cashCodeTitle": "Entrez le code envoyé par l'expéditeur du transfert",
    "summaryTitle": {
      "sentCashTransfer": "Transfert annulé",
      "receivedCashTransfer": "Transfert récupéré sur votre compte"
    }
  },
  "settings": {
    "title": "Paramètres",
    "changeLanguageTitle": "Langue",
    "changeLanguageDescription": "Choisissez la langue de l'application et des messages que nous vous envoyons.",
    "languageChangeConfirmation": "La langue a été changée avec succès",
    "languageChangeFailed": "Une erreur s'est produite lors du chargement de la langue",
    "changePincodeTitle": "Changer mon code secret",
    "changePincodeDescription": "Pour plus de securité, changez votre code secret régulièrement",
    "accountDeleteTitle": "Supprimer mon compte",
    "accountDeleteDescription": "Supprimer définitivement mon compte Skaleet."
  },
  "accountDeleteScreen": {
    "title": "Vous nous manquerez !",
    "subtitle": "Notez que vous n'avez pas besoin de supprimer votre compte si:",
    "button": "Supprimer mon compte",
    "explanation": "Votre demande va être envoyée à notre équipe. La suppression peut prendre plusieurs jours.",
    "options": {
      "changeProfile": {
        "title": "Vous voulez changer votre code",
        "description": "Modifiez votre code secret."
      },
      "unHappy": {
        "title": "Vous n'êtes pas heureux avec nous",
        "description": "Contactez-nous et dites nous pourquoi."
      }
    },
    "confirm": {
      "title": "Souhaitez-vous vraiment supprimer votre compte ?",
      "subtitle": "Votre compte sera entièrement supprimé et vos données et informations perdues",
      "placeholder": "Selectionnez la raison de suppression",
      "warning": "Cette action est définitive",
      "button": "Confirmer",
      "success": "La demande de fermeture de compte a été enregistrée"
    }
  },
  "accountDeleteMail": {
    "subject": "Demande de suppression du compte de {username}",
    "body": "Chère Madame, Cher Monsieur, je vous prie de bien vouloir procéder à la suppression de mon compte utilisateur."
  },
  "transactionFilter": {
    "all": "Toutes les transactions",
    "credit": "Recettes",
    "debit": "Dépenses",
    "searchDescription": "À l'aide du moteur de recherche retrouvez facilement vos dépenses.",
    "searchInputPlaceholder": "Rechercher",
    "timeRangeSelectionTitle": "Date personnalisée",
    "timeRange": {
      "month": "Ce mois",
      "week": "7 derniers jours",
      "quarter": "3 derniers mois",
      "from": "Du :",
      "to": "Au :"
    },
    "advancedSearch": "Recherche avancée.",
    "confirm": "Valider",
    "amountRangeSelectionTitle": "Filtrer par montant",
    "allAmountRange": "Tous les montants",
    "amountRange": {
      "min": "Min:",
      "max": "Max:"
    },
    "emptySearchResult": "Aucune transaction"
  },
  "resultScreen": {
    "activateCard": {
      "successTitle": "Félicitations !",
      "successSubtitle": "Votre carte a bien été activée !",
      "failureTitle": "Erreur",
      "failureSubtitle": "Veuillez nous excuser.",
      "failureDescription": "Il semble y avoir eu une erreur lors de l’activation de votre carte. Veuillez réessayer plus tard ou contacter notre service client."
    },
    "createCard": {
      "successTitle": "Félicitations !",
      "defaultSuccessSubtitle": "Votre carte a bien été créée !",
      "failureTitle": "Erreur",
      "failureSubtitle": "Veuillez nous excuser.",
      "failureDescription": "Il semble y avoir eu une erreur lors de la création de votre carte. Veuillez réessayer plus tard ou contacter notre service client."
    },
    "updateOutstanding": {
      "successTitle": "Félicitations !",
      "defaultSuccessSubtitle": "Votre modification à été prise en compte !",
      "failureTitle": "Erreur",
      "failureSubtitle": "Veuillez nous excuser.",
      "failureDescription": "Il semble y avoir eu une erreur lors de la modification de votre plafond. Veuillez réessayer plus tard ou contacter notre service client."
    },
    "refabricate": {
      "successTitle": "Félicitations !",
      "successSubtitle": "La demande de remplacement a été prise en compte !",
      "failureTitle": "Erreur",
      "failureSubtitle": "Veuillez nous excuser.",
      "failureDescription": "Il semble y avoir eu une erreur lors de la demande de remplacement de votre carte. Veuillez réessayer plus tard ou contacter notre service client."
    },
    "sensitiveDetails": {
      "successTitle": "Félicitations !",
      "successSubtitle": "Continuez pour consulter les informations de la carte",
      "failureTitle": "Erreur",
      "failureSubtitle": "Veuillez nous excuser.",
      "failureDescription": "Il semble y avoir eu une erreur lors de la récupération des informations de la carte. Veuillez réessayer plus tard ou contacter notre service client."
    },
    "done": "Terminer"
  },
  "createCard": {
    "title": "Nouvelle carte",
    "virtualCardtitle": "Nouvelle carte virtuelle",
    "loadProductsError": "Une erreur est survenue lors du chargement des produits.",
    "loadProductsRetry": "Réessayer",
    "stepSelection": {
      "title": "Sélection du compte",
      "selectAccountMessage": "Veuillez séléctionner le compte pour lequel vous désirez commander une carte :",
      "selectVirtualCardMessage": "Veuillez séléctionner le compte pour lequel vous désirez créer une carte virtuelle :",
      "selecProductMessage": "Quelle carte souhaitez vous commander ?",
      "selectProductForVirtualCardMessage": "Quelle carte virtuelle souhaitez vous créer ?",
      "orderCardButton": "Commander ma carte",
      "createVirtualCardButton": "Créer une carte virtuelle",
      "virtualCardTitle": "Obtenez instantanément une nouvelle carte virtuelle gratuite",
      "virtualCardDescription": "Pour des achats en ligne sécurisés sans crainte de perte de votre carte !",
      "accountLabel": "Compte n°{id}"
    },
    "stepCgu": {
      "title": "Conditions générales d'utilisation",
      "acceptMessage": "En activant le switch, vous confirmez avoir lu et accepté nos conditions générales d’utilisation",
      "next": "Suivant"
    }
  },
  "recharge": {
    "title": "Recharger mon compte",
    "description": "Sélectionner le compte à recharger",
    "noAccountAvailable": "Aucun compte n’est disponible",
    "noAccountExplanation": "Il semblerait que vous n’ayez aucun compte rechargeable.",
    "contactSupport": "Contacter le support",
    "rechargeButton": "Recharger"
  },
  "rechargeMethod": {
    "title": "Modes de recharge",
    "subtitle": "Comment voulez-vous recharger votre compte ?",
    "withCard": {
      "title": "Carte bancaire",
      "description": "Votre compte sera crédité instantanément. Retraits dans les banques partenaires partout dans le monde.",
      "instantTag": "Instantané"
    },
    "bankDebit": {
      "title": "Prélèvement bancaire",
      "description": "Alimenter votre compte à partir de votre compte externe {bankName}. Votre compte ne sera pas crédité instantanément.",
      "noBankDescription": "Alimenter votre compte à partir de votre compte externe. Votre compte ne sera pas crédité instantanément."
    }
  },
  "contactSupport": {
    "rechargeNotAvailableTitle": "Vous ne pouvez pas recharger votre compte ?",
    "rechargeNotAvailableMessage": "Veuillez prendre contact avec notre équipe support qui vous indiquera la marche à suivre.",
    "contactByPhone": "Nous contacter par téléphone au {phoneNumber}",
    "contactByEmail": "Nous contacter par email à {email}",
    "done": "Terminer"
  },
  "rechargeBankToWallet": {
    "title": "Recharge par prélèvement",
    "subtitle": "Montant à recharger",
    "accountWithdrawnTitle": "Compte externe prelevé",
    "accountWithdrawnMessage": "Modifiable par le menu Transferts",
    "iban": "IBAN",
    "amountToWithdraw": "Montant à prélever :",
    "recharge": "Recharger"
  },
  "rechargeByCard": {
    "title": "Recharge par carte",
    "subtitle": "Montant à recharger",
    "creditedAccountTitle": "Compte crédité",
    "accountId": "N°{id}",
    "amountToCredit": "Montant à créditer :",
    "recharge": "Recharger"
  },
  "rechargeSummary": {
    "title": "Succès",
    "rechargedAccount": "Compte rechargé",
    "rechargeMethod": "Mode de recharge",
    "bankToWalletMethod": "Prélèvement",
    "cardMethod": "Par carte",
    "rechargeAmount": "Montant rechargé",
    "done": "Terminer"
  },
  "recurringTransfer": {
    "endDateLabel": "Date de fin",
    "firstExecutionDateLabel": "Date de début",
    "frequencyLabel": "Fréquence",
    "switchLabel": "Rendre ce transfert récurrent",
    "optionLabel": {
      "daily": "Quotidien",
      "weekly": "Hebdomadaire",
      "twiceAMonth": "Bimensuel",
      "monthly": "Mensuel"
    },
    "recurringTransferTitleDetails": "Vers {destinationAccount}",
    "beneficiaryDetails": "Détails du bénéficiaire",
    "scheduledAmount": "Montant prévu",
    "beneficiaryAccount": "Compte du bénéficiaire",
    "beneficiaryBIC": "BIC du bénéficiaire",
    "recurringTransferDetails": "Détails du transfert récurrent",
    "sourceAccount": "Compte source",
    "frequency": "Fréquence",
    "nextExecutionDate": "Date de la prochaine exécution",
    "endDate": "Date de fin",
    "description": "Description"
  },
  "transactionCode": {
    "title": "Code de transaction",
    "description": "Créez un code pour l'utiliser dans un de nos points de transaction.",
    "noCodeAvailable": "Aucun code de transaction en cours",
    "noCodeAvailableExplanation": "Pour générer un nouveau code de transaction, obtenez le numéro à 6 chiffres du partenaire.",
    "codeTooltip": "Code",
    "newCodeButton": "Nouveau code",
    "modalBackButton": "Retour",
    "selectAcceptor": {
      "title": "Point de transaction",
      "description": "Entrez le numéro à 6 chiffres du point de transaction",
      "next": "Suivant"
    },
    "selectAmount": {
      "title": "Limite autorisée",
      "acceptorReminder": "Point de transaction : {acceptorId}",
      "currencyLabel": "Devise :",
      "amountLabel": "Montant :",
      "amountInformationLabel": "Montant maximal de la transaction (frais compris)",
      "next": "Générer mon code"
    },
    "pincode": {
      "title": "Code secret",
      "description": "Rentrez votre code secret pour terminer"
    },
    "summary": {
      "title": "Succès",
      "transactionCode": "Code de transaction",
      "transactionPoint": "Point de transaction",
      "maxAmount": "Montant maximum",
      "expiryDate": "Date d'expiration",
      "done": "Terminer"
    }
  },
  "trustChoice": {
    "title": "Se fier à ce navigateur ?",
    "explanation": "Si vous choisissez de faire confiance à ce navigateur, vous n'aurez pas à saisir de code de validation lors de votre prochaine connexion.",
    "laterButton": "Plus tard",
    "dontTrustButton": "Ne pas se fier",
    "trustButton": "Se fier"
  },
  "ubbleCheckScreen": {
    "success": {
      "title": "BIEN JOUÉ !",
      "subtitle": "Merci pour votre enregistrement !",
      "message": "Votre identité va être vérifiée de notre côté. Le processus de vérification peut prendre de quelques heures à quelques jours.\nVous pouvez maintenant vous connecter à votre espace ou fermer cette fenêtre.",
      "button": "Continuer"
    },
    "fail": {
      "title": "DÉSOLÉ !",
      "subtitle": "Quelque chose s'est mal passé pendant l'enregistrement...",
      "message": "Vous pouvez réessayer en utilisant le même lien qu'avant.\nSi vous rencontrez une autre erreur, veuillez prendre contact avec notre équipe par téléphone ou par e-mail en utilisant les boutons ci-dessous.",
      "byPhone": "Téléphone",
      "byEmail": "Email"
    }
  },
  "justifyScreen": {
    "title": "Justifier ma dépense",
    "subtitle": "Ajouter et modifier un justificatif",
    "done": "Terminer",
    "attachments": {
      "title": "Justificatifs",
      "sectionTitle": "Téléchargez ou déposez un justificatif",
      "sectionSubtitle": "Au format pdf, jpeg, png",
      "addButtonLabel": "Ajouter un justificatif",
      "addOtherButtonLabel": "Ajouter un autre justificatif",
      "deletePrompt": {
        "title": "Supprimer le justificatif",
        "message": "Êtes-vous sûr de vouloir supprimer ce justificatif ?",
        "cancelButton": "Annuler",
        "confirmButton": "Supprimer"
      }
    },
    "spendings": {
      "title": "Sous-dépenses",
      "sectionTitle": "Ajouter une sous-dépense",
      "sectionSubtitle": "Déclarez les sous-dépenses de votre justificatif",
      "addButtonLabel": "Ajouter une sous-dépense",
      "addOtherButtonLabel": "Ajouter une autre sous-dépense",
      "addModal": {
        "title": "Nouvelle sous-dépense",
        "editTitle": "Modifier la sous-dépense",
        "message": "Détails complémentaires du justificatif",
        "createButton": "Valider",
        "editButton": "Enregistrer"
      },
      "addForm": {
        "titleLabel": "Titre",
        "categoryLabel": "Catégorie",
        "subcategoryLabel": "Sous-catégorie",
        "amountLabel": "Montant",
        "additionalDataLabel": "Donnée additionnelle"
      },
      "deletePrompt": {
        "title": "Supprimer la sous-dépense",
        "message": "Êtes-vous sûr de vouloir supprimer cette sous-dépense ?",
        "cancelButton": "Annuler",
        "confirmButton": "Supprimer"
      }
    }
  },
  "selectField": {
    "select": "Sélectionner un(e)",
    "select_an_option": "Sélectionner une option"
  },
  "refundTransactionModal": {
    "title": "Demander un remboursement",
    "message": "Pour demander un remboursement, choisissez un motif ci-dessous:",
    "reasons": {
      "AC03": "Mauvais IBAN",
      "AM09": "Mauvais montant",
      "CUST": "Décision client"
    },
    "confirmButton": "Confirmer",
    "cancelButton": "Annuler",
    "success": "Votre demande de remboursement a été envoyée"
  },
  "upcomingTransfers": {
    "description": "Voir et gérer vos transferts à venir",
    "editTransfer": "Modifier le transfert récurrent",
    "loadMore": "Charger plus",
    "nextOn": "Prochain le",
    "status": {
      "activated": "Activé",
      "deactivated": "En pause",
      "expired": "Exécuté",
      "deleted": "Annulé"
    },
    "deletePrompt": {
      "title": "Supprimer Transfert à venir ?",
      "message": "Souhaitez-vous vraiment supprimer le prochain transfert {id} ?"
    },
    "confirmButton": "Confirmer",
    "sourceAccount": "Compte source",
    "scheduledAmount": "Montant prévu",
    "message": "Message",
    "startDate": "Date de début",
    "frequency": "Fréquence",
    "endDate": "Date de fin"
  },
  "createSavingsAccount": {
    "title": "Ouvrir un compte d'épargne",
    "selectAccount": "Sélectionnez le type de compte d'épargne",
    "initialDeposit": "Dépôt initial",
    "accountToCollect": "Compte à collecter",
    "duration": "Durée",
    "sendRequest": "Envoyer une demande",
    "selectAccountTypeLabel": "Sélectionnez le type de compte à collecter",
    "selectAccountType": {
      "INTERNAL": "Interne",
      "EXTERNAL": "Externe"
    },
    "creationSuccessMessage": "Votre demande de création de compte d'épargne a bien été envoyée"
  }
}
